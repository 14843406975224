import { useEffect, useState, useContext } from "react";
import { db } from "../auth/firebase";
import { AuthContext } from "../auth/authContext";
import {
  Input,
  Table,
  Form,
  Label,
  Modal,
  Icon,
  Button,
  Message,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";

export const CompanySettings = () => {
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const { currentUser, currentUserData, company } = useContext(AuthContext);
  const [state, setState] = useState({
    phoneNumber: "",
    email: "",
    address: "",
    plan: { text: "", value: "" },
    depositOwner: "0",
    depositRenter: "0",
    newConnectionFee: "0",
    disconnectFee: "0",
    membershipFee: "0",
    setUpFee: "0",
    lateCharge: "0",
    returnedCheckFee: "0",
    newMeterInstall: "0",
  });
  const [open, setOpen] = useState(false);
  const [passwordState, setPasswordState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  useEffect(() => {
    const fetchUser = async () => {
      if (currentUserData) {
        setState({
          ...state,
          phoneNumber: currentUserData.number,
          email: currentUserData.email,
          address: currentUserData.address,
          plan: currentUserData.plan,
          depositOwner: company?.depositOwner,
          depositRenter: company?.depositRenter,
          newConnectionFee: company?.newConnectionFee,
          disconnectFee: company?.disconnectFee,
          membershipFee: company?.membershipFee,
          setUpFee: company?.setUpFee,
          lateCharge: company?.lateCharge,
          returnedCheckFee: company?.returnedCheckFee,
          newMeterInstall: company?.newMeterInstall,
        });
      }
    };
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserData, company]);

  const handlePasswordChange = async () => {
    setSuccess("");
    setErrors("");
    if (passwordState.newPassword !== passwordState.confirmNewPassword) {
      setErrors("New password and confirm password do not match");
      return;
    }
    try {
      if (currentUser) {
        const credential = EmailAuthProvider.credential(
          currentUserData?.email,
          passwordState.currentPassword
        );
        await reauthenticateWithCredential(currentUser, credential);
        await updatePassword(currentUser, passwordState.newPassword);
        setSuccess("Password updated successfully");
      }
    } catch (e) {
      const error = e as any;
      switch (error.code) {
        case "auth/wrong-password":
          setErrors("Wrong Password");
          break;
        case "auth/weak-password":
          setErrors("Password must be at least 6 characters");
          break;
        default:
          console.log(e);
          break;
      }
    }
    setOpen(false);
  };
  const handleSubmit = async () => {
    if (currentUser) {
      await updateDoc(doc(db, "users/", currentUser.uid), {
        number: state.phoneNumber,
        email: state.email,
        address: state.address,
      });
      if (company) {
        await updateDoc(doc(db, "companies/", company?.ID), {
          depositOwner: state.depositOwner,
          depositRenter: state.depositRenter,
          newConnectionFee: state.newConnectionFee,
          disconnectFee: state.disconnectFee,
          membershipFee: state.membershipFee,
          setUpFee: state.setUpFee,
          lateCharge: state.lateCharge,
          returnedCheckFee: state.returnedCheckFee,
          newMeterInstall: state.newMeterInstall,
        });
      }
    }
    setUpdateSuccess(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      setUpdateSuccess(false);
    }, 3000);
  };

  return (
    <div
      style={{ padding: "70px 16px", paddingTop: "10%", overflowX: "scroll" }}
    >
      <Form onSubmit={handleSubmit}>
        {updateSuccess && (
          <Message
            positive
            onDismiss={() => setUpdateSuccess(false)}
            header="Success!"
            content="Your changes have been saved."
          />
        )}
        <Table className="usd-bordered" id="companySetting">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="4">
                <div
                  className="usd-green-background usd-center usd-text-white"
                  style={{ paddingTop: "2%", paddingBottom: "2%" }}
                >
                  <h3>Profile Settings</h3>
                  <h6>*Any changes will need password verification</h6>
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body id="settings">
            <Table.Row
              id="profileSetting"
              className="usd-green-background usd-text white"
            >
              <Table.Cell>
                <Link
                  to="https://billing.stripe.com/p/login/fZeeYl2n88Ut4YEfYY"
                  className="usd-button usd-white usd-text-blue"
                >
                  Change Subscription
                </Link>
              </Table.Cell>
            </Table.Row>
            <Table.Row
              id="profileSetting"
              className="usd-blue-background usd-text-white"
            >
              <Table.Cell>
                <h4>Phone Number</h4>
                <Form.Input
                  value={`${state?.phoneNumber}`}
                  name="phoneNumber"
                  className="usd-light-grey"
                  onChange={(event, value) => {
                    setState({
                      ...state,
                      phoneNumber: value.value,
                    });
                  }}
                />
              </Table.Cell>
              <Table.Cell>
                <h4>Email</h4>
                <Form.Input
                  value={`${state.email}`}
                  name="email"
                  className="usd-green-background usd-text-white"
                  onChange={(event, value) => {
                    setState({ ...state, email: value.value });
                  }}
                />
              </Table.Cell>
              <Table.Cell>
                <h4>Address</h4>
                <Form.Input
                  value={`${state?.address}`}
                  name="address"
                  className="usd-green-background usd-text-white"
                  onChange={(event, value) => {
                    setState({ ...state, address: value.value });
                  }}
                />
              </Table.Cell>
              <Table.Cell>
                <h4>Password</h4>
                <Modal
                  open={open}
                  onOpen={() => setOpen(true)}
                  trigger={
                    <Form.Button
                      style={{ textAlign: "center", margin: "auto" }}
                    >
                      Change Password
                    </Form.Button>
                  }
                >
                  <Modal.Header
                    style={{ textAlign: "center" }}
                    className="usd-green-background usd-text-white"
                  >
                    Change Password
                    <br />
                  </Modal.Header>
                  <Modal.Content>
                    {errors && <h6 style={{ color: "red" }}>{errors}</h6>}
                    <Form.Input
                      type={showPassword ? "text" : "password"}
                      label="Current Password"
                      name="currentPassword"
                      defaultValue={passwordState.currentPassword}
                      onChange={(event, value) => {
                        setPasswordState({
                          ...passwordState,
                          currentPassword: value.value,
                        });
                      }}
                      placeholder="Confirm Password"
                      required
                      icon={
                        !showPassword ? (
                          <Icon
                            name="eye"
                            link
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <Icon name="eye slash" />
                        )
                      }
                    />
                    <Form.Input
                      type={showPassword ? "text" : "password"}
                      label="New Password"
                      name="newPassword"
                      defaultValue={passwordState.newPassword}
                      onChange={(event, value) => {
                        setPasswordState({
                          ...passwordState,
                          newPassword: value.value,
                        });
                      }}
                      placeholder="New Password"
                      required
                      icon={
                        !showNewPassword ? (
                          <Icon
                            name="eye"
                            link
                            onClick={() => setShowNewPassword(!showNewPassword)}
                          />
                        ) : (
                          <Icon name="eye slash" />
                        )
                      }
                    />
                    <Form.Input
                      type={showPassword ? "text" : "password"}
                      label="Confirm New Password"
                      name="confirmNewPassword"
                      defaultValue={passwordState.confirmNewPassword}
                      onChange={(event, value) => {
                        setPasswordState({
                          ...passwordState,
                          confirmNewPassword: value.value,
                        });
                      }}
                      placeholder="Confirm New Password"
                      required
                      icon={
                        !showConfirmPassword ? (
                          <Icon
                            name="eye"
                            link
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          />
                        ) : (
                          <Icon name="eye slash" />
                        )
                      }
                    />
                    <Form.Button onClick={handlePasswordChange}>
                      Submit
                    </Form.Button>
                  </Modal.Content>
                </Modal>
                {/* <h4>Change Password</h4>
                <Form.Input
                  placeholder="************"
                  type="text"
                  id="passwordInput"
                  name="changePassword"
                  className="usd-green-background usd-text-white"
                  disabled
                />
                <Link
                  to=""
                  className="usd-button usd-green-background usd-center"
                  style={{ height: 32, paddingTop: 0, paddingBottom: 0 }}
                >
                  <FontAwesomeIcon icon={faLock} /> */}
                {/* </Link> */}
              </Table.Cell>
            </Table.Row>
            <Table.Row
              id="subscriptionOptions"
              className="usd-green-background usd-text-white"
            >
              <Table.Cell>
                <h4>Subscription</h4>
                <Input
                  value={`${state.plan?.text}`}
                  name="subscription"
                  className="usd-green-background usd-text-white"
                  disabled
                />
              </Table.Cell>
              <Table.Cell>
                <h4>Subscription Billing Date</h4>
                <Input
                  name="subscriptionDate"
                  className="usd-green-background usd-text-white"
                  disabled
                />
              </Table.Cell>
              <Table.Cell>
                <h4>Subscription Auto Renewal Date</h4>
                <Input
                  name="subscriptionRenew"
                  className="usd-blue-background usd-text-white"
                  disabled
                />
              </Table.Cell>
              <Table.Cell>
                <h4>Deposit: </h4>
                <h4>Owner</h4>
                <Form.Input
                  labelPosition="right"
                  value={state.depositOwner}
                  name="depositOwner"
                  type="number"
                  className="usd-blue-background usd-text-white"
                  onChange={(event, { value }) => {
                    setState({ ...state, depositOwner: value });
                  }}
                >
                  <Label basic>$</Label>
                  <input />
                  <Label>.00</Label>
                </Form.Input>

                <h4>Renter</h4>
                <Form.Input
                  labelPosition="right"
                  value={state.depositRenter}
                  name="depositRenter"
                  type="number"
                  // className="usd-blue-background usd-text-white"
                  onChange={(event, { value }) => {
                    setState({ ...state, depositRenter: value });
                  }}
                >
                  <Label basic>$</Label>
                  <input />
                  <Label>.00</Label>
                </Form.Input>
              </Table.Cell>
            </Table.Row>
            <Table.Row
              id="newServiceFees"
              className="usd-blue-background usd-text-white"
            >
              <Table.Cell>
                <h4>New Connection Fees</h4>
                <Form.Input
                  labelPosition="right"
                  value={state.newConnectionFee}
                  name="newConnectionFee"
                  type="number"
                  className="usd-blue-background usd-text-white"
                  onChange={(event, { value }) => {
                    setState({ ...state, newConnectionFee: value });
                  }}
                >
                  <Label basic>$</Label>
                  <input />
                  <Label>.00</Label>
                </Form.Input>
              </Table.Cell>
              <Table.Cell>
                <h4>Disconnect Fees</h4>
                <Form.Input
                  labelPosition="right"
                  value={state.disconnectFee}
                  name="disconectFee"
                  type="number"
                  className="usd-green-background usd-text-white"
                  onChange={(event, { value }) => {
                    setState({ ...state, disconnectFee: value });
                  }}
                >
                  <Label basic>$</Label>
                  <input />
                  <Label>.00</Label>
                </Form.Input>
              </Table.Cell>
              <Table.Cell>
                <h4>Membership Fee</h4>
                <Form.Input
                  labelPosition="right"
                  value={state.membershipFee}
                  name="membershipFee"
                  type="number"
                  className="usd-green-background usd-text-white"
                  onChange={(event, { value }) => {
                    setState({ ...state, membershipFee: value });
                  }}
                >
                  <Label basic>$</Label>
                  <input />
                  <Label>.00</Label>
                </Form.Input>
              </Table.Cell>

              <Table.Cell>
                <h4>SetUp Fee</h4>
                <Form.Input
                  type="number"
                  labelPosition="right"
                  value={state.setUpFee}
                  name="setUpFee"
                  className="usd-green-background usd-text-white"
                  onChange={(event, { value }) => {
                    setState({ ...state, setUpFee: value });
                  }}
                >
                  <Label basic>$</Label>
                  <input />
                  <Label>.00</Label>
                </Form.Input>
              </Table.Cell>
            </Table.Row>
            <Table.Row
              id="newServiceFees"
              className="usd-green-background usd-text-white"
            >
              <Table.Cell>
                <h4>Late Charge</h4>
                <Form.Input
                  type="number"
                  labelPosition="right"
                  value={state.lateCharge}
                  name="lateCharge"
                  className="usd-green-background usd-text-white"
                  onChange={(event, value) => {
                    setState({ ...state, lateCharge: value.value });
                  }}
                >
                  <Label basic>%</Label>
                  <input />
                </Form.Input>
              </Table.Cell>
              <Table.Cell>
                <h4>Returned Check Fee</h4>
                <Form.Input
                  type="number"
                  labelPosition="right"
                  value={state.returnedCheckFee}
                  name="returnedCheckFee"
                  className="usd-green-background usd-text-white"
                  onChange={(event, { value }) => {
                    setState({ ...state, returnedCheckFee: value });
                  }}
                >
                  <Label basic>$</Label>
                  <input />
                  <Label>.00</Label>
                </Form.Input>
              </Table.Cell>
              <Table.Cell>
                <h4>New Meter Install</h4>
                <Form.Input
                  labelPosition="right"
                  value={state?.newMeterInstall}
                  type="number"
                  name="newMeterInstall"
                  className="usd-green-background usd-text-white"
                  onChange={(event, { value }) => {
                    setState({ ...state, newMeterInstall: value });
                  }}
                >
                  <Label basic>$</Label>
                  <input />
                  <Label>.00</Label>
                </Form.Input>
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row
              id="newServiceFees"
              className="usd-blue-background usd-text-white"
            >
              <Table.Cell>
                <h4>Total Money Back</h4>
                <Input
                  value={`$${company?.debtPaid}`}
                  name="TotalMoney"
                  className="usd-blue-background usd-text-white"
                  disabled
                />
              </Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <Button
                  type="submit"
                  style={{
                    borderRadius: 20,
                    backgroundColor: "#719229",
                    color: "white",
                  }}
                  id="submit"
                >
                  Submit Changes
                </Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Form>
    </div>
  );
};
