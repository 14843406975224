import React, { useEffect, useState } from "react";
import { db, functions } from "../auth/firebase";
import { Button, Card, CardDescription, Form, Header, Loader, Modal } from "semantic-ui-react";
import { routes } from "../routes";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
export const ClientNewServicesSearch = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    CustomerName: "",
    email: "",
    DL: "",
    DLstate: "",
  });
  const handleSubmit = async () => {
    setLoading(true);
    {
      const customerName = state.CustomerName;
      const email = state.email;
      const license = state.DL + " " + state.DLstate;
    }
    if (loading) {
      return <Loader active />;
    }
  };
  return (
    <div>

    </div>
  )
};