import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  NextOrObserver,
  User,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  getDocs,
  query,
  orderBy,
  setDoc,
} from "firebase/firestore";
import React from "react";
import { getFunctions } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyARD2YsBVhxy5H4p4_C5WsLL_pjkTqtn70",
  authDomain: "utility-service-databases.firebaseapp.com",
  projectId: "utility-service-databases",
  storageBucket: "utility-service-databases.appspot.com",
  messagingSenderId: "334699535433",
  appId: "1:334699535433:web:56e83bb016569f97ca7398",
  measurementId: "G-MD1PPRH9TR",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);

// Initialize App Check with reCAPTCHA v3
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdUDzwqAAAAAFAPYfj7OE_KSwKfc1rQv3Y3fTHp'),
  isTokenAutoRefreshEnabled: true, // Automatically refresh App Check token
});

export const signInUser = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const userStateListener = (callback: NextOrObserver<User>) => {
  return onAuthStateChanged(auth, callback);
};

export const SignOutUser = async () => await signOut(auth);

export const registerUser = async (data: any) => {
  try {
    await createUserWithEmailAndPassword(auth, data.email, data.password).then(
      async (userCredential) =>
        await setDoc(doc(db, "users/", userCredential.user.uid), {
          companyN: data.companyId,
          number: data.phoneNumber,
          email: data.email,
          address: data.address,
          plan: data.productValue,
        })
    );
  } catch (error) {
    console.error("Error registering user:", error);
  }
};

export const getCompaniesList = async () => {
  const options = [{}];
  const querySnapshot = await getDocs(
    query(collection(db, "companies"), orderBy("Name"))
  );
  querySnapshot.forEach((snapshot) => {
    options.push({ text: snapshot.data().name, value: snapshot.id });
  });
  return options;
};
