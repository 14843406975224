import React, { useEffect } from "react";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "./assets/style.css";

Modal.setAppElement("#root");

export const PaymentsForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { amount } = location.state || { amount: 0 }; // Get the debt amount from state

  const closeModal = () => {
    navigate(-1);
  };

  const applicationId = process.env.REACT_APP_SQUARE_APP_ID;

  if (!applicationId) {
    throw new Error("REACT_APP_SQUARE_APP_ID is not set");
  }

  return (
    <Modal isOpen={true} onRequestClose={closeModal} contentLabel="Payment Form">
      <main>
        <h2>Pay Outstanding Debt</h2>
        <PaymentForm
          applicationId={applicationId}
          locationId="RCXCSJ0868F5"
          cardTokenizeResponseReceived={async (token, buyer) => {
            alert(JSON.stringify(token, null, 2));
          }}
        >
          <CreditCard />
        </PaymentForm>
      </main>
      <button onClick={closeModal}>Cancel</button>
    </Modal>
  );
};

export default PaymentsForm;
