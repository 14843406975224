import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { rootRoutes, routes } from "./routes";
import { CompanyPage } from "./company/companyPage";
import { AdminPage } from "./company/adminPage";
import { CompanySettings } from "./company/companySettings";
import RequireAuth from "./routes/protectedRoute";
import { Home } from "./home";
import { CompanyLogin } from "./company/companyLogin";
import { ClientSearch } from "./client/clientSearch";
import { ClientPage } from "./client/clientPage";
import { CompanyRegistration } from "./company/companyRegistration";
import { ClientNewServices } from "./client/clientNewServices";
import { ClientNewServicesSearch } from "./client/clientNewServicesSearch";
import { ClientSearchResults } from "./client/clientSearchResults";
import { Navigation } from "./navigation";
import { ViewAllNew } from "./company/viewAllNew";
import { ViewAllBadDebt } from "./company/viewAllBadDebt";
import { ViewAllPaid } from "./company/viewAllPaid";
import { ViewAllCompanies } from "./company/viewAllCompanies";
import { PaymentsForm } from "./paymentForm";

function App() {
  return (
    <div>
      <Navigation />
      <Routes>
        <Route path={rootRoutes.home} element={<Home />} />
        <Route path={routes.home.companyLogin} element={<CompanyLogin />} />
        <Route path={routes.home.clientSearch} element={<ClientSearch />} />
        <Route path={routes.home.clientPage} element={<ClientPage />} />
        <Route path = {routes.home.paymentForm} element = {<PaymentsForm/>}/>
        <Route
          path={routes.home.companyRegistration}
          element={<CompanyRegistration />}
        />
        <Route
          path={routes.home.clientNewServicesSearch}
          element={<ClientNewServicesSearch />}
        />
        <Route
          path={routes.home.clientNewServices}
          element={<ClientNewServices />}
        />
        <Route
          path={routes.home.clientSearchResults}
          element={<ClientSearchResults />}
        />
        <Route
          path={routes.authenticated.companyPage}
          element={
            <RequireAuth>
              <CompanyPage />
            </RequireAuth>
          }
        />
        <Route
          path={routes.authenticated.profileSettings}
          element={
            <RequireAuth>
              <CompanySettings />
            </RequireAuth>
          }
        />
        <Route
          path={routes.authenticated.admin}
          element={
            <RequireAuth>
              <AdminPage />
            </RequireAuth>
          }
        />
        <Route
          path={routes.authenticated.viewAllNew}
          element={
            <RequireAuth>
              <ViewAllNew />
            </RequireAuth>
          }
        />
        <Route
          path={routes.authenticated.viewAllBadDebt}
          element={
            <RequireAuth>
              <ViewAllBadDebt />
            </RequireAuth>
          }
        />
        <Route
          path={routes.authenticated.viewAllPaid}
          element={
            <RequireAuth>
              <ViewAllPaid />
            </RequireAuth>
          }
        />
        <Route
          path={routes.authenticated.viewAllCompanies}
          element={
            <RequireAuth>
              <ViewAllCompanies />
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
