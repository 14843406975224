import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Form,
  Header,
  Icon,
  Loader,
  Modal,
} from "semantic-ui-react";
import { rootRoutes, routes } from "../routes";
import { auth, db, functions } from "../auth/firebase";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { HttpsCallableResult, httpsCallable } from "firebase/functions";

interface LineItem {
  id: string;
  priceId: string;
  productId: string;
  description: string;
}

interface Session {
  id: string;
  customerDetails: {
    email: string;
    name: string;
    phone: string;
    address: {
      line1: string;
      line2: string;
      city: string;
      postal_code: string;
      state: string;
      country: string;
    };
  };
  line_items: LineItem[];
}

export const CompanyRegistration = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [companies, setCompanies] = useState<any[]>([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const currentDate = new Date();
  const [state, setState] = useState({
    productValue: { text: "", value: "" },
    companyId: "",
    companyN: "",
    phoneNumber: "",
    email: "",
    address: "",
    password: "",
    confirmPassword: "",
    signUpDate: new Date(),
    totalDebtPaid: "",
    biWeeklyDebtPaid: "",
    deposit: "",
    newConnectionFee: "",
    disconnectFee: "",
    membershipFee: "",
    other: "",
    authorizedSignature: "",
    title: "",
    termsAndConditions: false,
  });

  useEffect(() => {
    const getSession = httpsCallable(functions, "session");
    if (id) {
      getSession(id).then((result: HttpsCallableResult) => {
        const sessionData = result.data as Session;
        if (!sessionData.customerDetails && !sessionData.line_items) {
          navigate(`${rootRoutes.home}`);
        } else {
          const addressDetails = sessionData.customerDetails.address;
          var address = addressDetails.line1 + " ";
          if (addressDetails.line2) {
            address += addressDetails.line2 + " ";
          }
          setState({
            ...state,
            email: sessionData.customerDetails.email,
            phoneNumber: sessionData.customerDetails.phone,
            address: address +=
              addressDetails.city +
              ", " +
              addressDetails.state +
              " " +
              addressDetails.postal_code,
            productValue: {
              text: sessionData.line_items[0].description,
              value: sessionData.line_items[0].priceId,
            },
          });
        }
      });
    } else {
      navigate(`${rootRoutes.home}#pricing`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    (async () => {
      let options: any[] = [];
      const querySnapshot = await getDocs(
        query(collection(db, "companies"), orderBy("Name"))
      );
      querySnapshot.forEach((snapshot) => {
        options.push({ text: snapshot.data().Name, value: snapshot.id });
      });
      setCompanies(options);
      setLoading(false);
    })();
  }, []);

  const handleSubmit = async () => {
    setErrorMessage("");
    if (state.password !== state.confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    } else {
      try {
        await createUserWithEmailAndPassword(
          auth,
          state.email,
          state.password
        ).then(async (userCredential) => {
          await setDoc(doc(db, "users/", userCredential.user.uid), {
            companyN: state.companyId,
            number: state.phoneNumber,
            email: state.email,
            address: state.address,
            plan: state.productValue,
            signUpDate: state.signUpDate,
            totalDebtPaid: state.totalDebtPaid,
            biWeeklyDebtPaid: state.biWeeklyDebtPaid,
            deposit: state.deposit,
            newConnectionFee: state.newConnectionFee,
            disconnectFee: state.disconnectFee,
            membershipFee: state.membershipFee,
            other: state.other,
            termsAndConditions: state.termsAndConditions,
            authorizedSignature: state.authorizedSignature,
            title: state.title,
            role: "user",
            numClients: 0,
          });
          navigate(routes.authenticated.companyPage);
        });
      } catch (e) {
        const error = e as any;
        switch (error.code) {
          case "auth/email-already-in-use":
            setErrorMessage("This email is already in use. Sign in.");
            break;
          case "auth/invalid-email":
            setErrorMessage("Please provide a valid email address");
            break;
        }
      }
    }
  };
  if (loading) {
    return <Loader active />;
  }
  return (
    <div
      className="usd-container usd-light-grey"
      style={{ padding: "128px 16px" }}
      id="companyLogIn"
    >
      <Card style={{ width: 600, height: 800, padding: 25, margin: "auto" }}>
        <Card.Header style={{ textAlign: "center" }}>
          <Header as="h1">Sign Up</Header>
          <Header as="h4" style={{ marginTop: 0, marginBottom: 10 }}>
            Sign up to create an account
          </Header>
        </Card.Header>
        <Form onSubmit={handleSubmit}>
          <Card.Description style={{ marginBottom: 10 }}>
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
            <Form.Dropdown
              search
              selection
              options={companies}
              onChange={(event, value) => {
                if (value.value) {
                  console.log(value);
                  setState({ ...state, companyId: value.value?.toString() });
                }
              }}
              placeholder="Select a Company"
              defaultValue=""
              required
            />
            <Form.Input value={state.productValue.text} disabled required />
            <Form.Input
              label="Phone Number"
              name="phoneNumber"
              value={state.phoneNumber}
              placeholder="Enter Phone Number"
              onChange={(event, value) => {
                setState({ ...state, phoneNumber: value.value });
              }}
              required
            />
            <Form.Input
              label="Address"
              name="address"
              value={state.address}
              placeholder="Enter Company's Address"
              onChange={(event, value) => {
                setState({ ...state, address: value.value });
              }}
              required
            />
            <Form.Input
              label="Email"
              name="email"
              value={state.email}
              placeholder="Enter email"
              onChange={(event, value) => {
                setState({ ...state, email: value.value });
              }}
              required
            />

            <Form.Input
              type={showPassword ? "text" : "password"}
              label="Password"
              name="password"
              defaultValue={state.password}
              onChange={(event, value) => {
                setState({ ...state, password: value.value });
              }}
              placeholder="Enter Password"
              required
              icon={
                !showPassword ? (
                  <Icon
                    name="eye"
                    link
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <Icon name="eye slash" />
                )
              }
            />
            <Form.Input
              type={showPassword ? "text" : "password"}
              label="Confirm Password"
              name="password"
              defaultValue={state.confirmPassword}
              onChange={(event, value) => {
                setState({ ...state, confirmPassword: value.value });
              }}
              placeholder="Confirm Password"
              required
              icon={
                !showPassword ? (
                  <Icon
                    name="eye"
                    link
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                ) : (
                  <Icon name="eye slash" />
                )
              }
            />

            <Modal
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              trigger={
                <Form.Checkbox
                  inline
                  name="userAgreement"
                  label="User Agreement"
                  value="agreed"
                  required
                />
              }
            >
              <Modal.Header>Terms of Service</Modal.Header>
              <Modal.Content scrolling>
                Utility Service Database ( USD ) is a service to help utility
                companies collect unpaid debt.
                <br />
                1. Members (Utilities) of this service agree allow USD to
                collect unpaid debt and remit to the Member the full amount of
                debt entered into database by that Member for each account when
                collected.
                <br />
                2. All correspondence from any debtor (former Customer) must be
                directed to USD for collection and clearance for new service in
                the Database. Debtor cannot contact any utility holding a debt
                in their name to clear said debt after Member Has entered debtor
                into the Database as bad debt.
                <br /> 3. Member agrees that after an account is entered into
                the database that only USD can collect the debt. Members will
                advise debtors of the contact information for USD (Phone number,
                website) to USD and all payments to clear debt through USD. When
                payment of the debt is collected and debtor is cleared for new
                service in the Database, The Member will receive payment of full
                amount owed for that account from USD.
                <br />
                4. Any Member that collects directly on any account that has
                been entered into the Database will Be subject to dismissal from
                the database, and all debt entered into the database deleted.
                <br />
                5. All members of USD must adopt either a resolution by the
                board of directors or an ordinance By its council of alderman to
                use the USD program.
                <br />
                6. Customers must be aware either verbally Or in writing that
                their name and identification number (Driver’s license, State
                Issued ID#) will Be entered into the database for the search.
                For new customers that have cleared the database, Members will
                enter customer into the database by Name, ID #, Account #, And
                Address. Customer status with members company will be listed as
                (ACTIVE) in the Database until which time customer has left the
                company with an uncollected debt. At which time member will edit
                the customers status to (BAD DEBT) and the amount of the debt
                recorded into the database for collection. If new customers
                entered into the database (ACTIVE) by any member has an
                outstanding debt entered by another member of the database at a
                later date, USD will detect the entry and notify the customer by
                letter of the outstanding debt.
                <br />
                7. If member does not require identification such as drivers
                license or valid pictured ID for customers to connect to their
                system, then member must adopt an ordinance or resolution to
                require this information before becoming a member of USD. All
                entries into the database will require customer name (account
                holder) as it appears on their valid identification and the
                identification number. Customer must be at least 18 years of age
                before they are accepted into the database. All debt entered
                into the database for a customer(account) must be the final
                amount owed on that account to clear the account.
                <br />
                8. The member will be responsible for debt amounts for any
                customer being correct. Any misprint of the amount of debt is
                the sole responsibility of the member. Fraudulent information
                into the database can result in dismissal from USD and
                prosecution by local and state laws. Ordinances and/or
                resolutions adopted by the member to join USD or to modify
                customer membership requirements are the responsibility of the
                member. A certified copy of the ordinance or resolution must be
                mailed to USD before activation. USD is not responsible for
                legal requirements of the member that have not been meet before
                the activation of the USD service.
                <br /> Name of Member (City, Village,Town,Utility System):{" "}
                <b>{state?.companyN}</b>
                <br />
                Address: <b>{state?.address}</b>
                <br />
                Phone: <b>{state?.phoneNumber} </b>
                <br /> * By signing below the above-named USD Member agrees to
                the Terms and Conditions of Membership to the Utility Service
                Database LLC.
                <br />
                Authorized Signature:
                <Form.Input
                  onChange={(event, value) => {
                    setState({ ...state, authorizedSignature: value.value });
                  }}
                  required
                ></Form.Input>
                Title:
                <Form.Input
                  onChange={(event, value) => {
                    setState({ ...state, title: value.value });
                  }}
                  required
                ></Form.Input>
                Date:
                <b> {currentDate.toDateString()}</b>
                <Form>
                  <br />
                  <Modal.Actions>
                    <Form.Button
                      width={2}
                      size="mini"
                      style={{
                        fontWeight: "bold",
                        paddingTop: "10%",
                        backgroundColor: "#719229",
                        color: "white",
                      }}
                      id="submit"
                      onClick={() => setOpen(false)}
                    >
                      Agree
                    </Form.Button>
                  </Modal.Actions>
                </Form>
              </Modal.Content>
            </Modal>
          </Card.Description>
          <div style={{ display: "flex" }}>
            <Form.Button
              type="submit"
              style={{
                fontWeight: "bold",
                paddingTop: "10%",
                backgroundColor: "#719229",
                color: "white",
              }}
              id="submit"
            >
              Sign Up
            </Form.Button>
            <Button
              basic
              style={{
                fontWeight: "bold",
                height: 36,
              }}
              onClick={() => {
                navigate(routes.home.companyLogin);
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};
