import React from "react";
import clientBillPayLogo from "../assets/ClientBillPay.png";
import clientNewServicesLogo from "../assets/ClientNewServicesLogo.png";
import { Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { routes } from "../routes";

export const ClientPage = () => {
  return (
    <header>
      <div
        className="usd-section usd-center"
        style={{ paddingTop: "10%", display: "flex" }}
      >
        <div className="usd-section usd-half usd-center usd-cell-row">
          <Link to={routes.home.clientSearch} className="usd-button">
            <Image
              src={clientBillPayLogo}
              className="usd-hover-opacity"
              alt="billPayment"
            />
          </Link>
          <h2 className="usd-center" style={{ paddingBottom: "20%" }}>
            Customer: Pay Bill
          </h2>
        </div>
        <div className="usd-section usd-half usd-center usd-cell-row">
          <Link to={routes.home.clientNewServices} className="usd-button">
            <Image
              src={clientNewServicesLogo}
              className="usd-hover-opacity"
             
              alt="newService"
            />
          </Link>
          <h2
            className="usd-center"
          >
            Customer: New Services
          </h2>
        </div>
      </div>
    </header>
  );
};
