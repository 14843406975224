import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form } from "semantic-ui-react";
import { routes } from "../routes";

export const ClientSearch = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    name: "",
    phoneNumber: "",
    billingAddress: "",
    serviceAddress: "",
    license: "",
  });
  const handleSubmit = () => {
    navigate(routes.home.clientSearchResults, { state: { data: state } });
  };

  return (
    <Form id="customers" onSubmit={handleSubmit}>
      <div
        className="usd-container usd-light-grey"
        style={{ padding: "70px 16px", paddingTop: "10%" }}
        id="paidTable"
      >
        {/* <div style={{ padding: "70px auto" }}> */}
        <Card style={{ width: 600, height: 500, padding: 25, margin: "auto" }}>
          <Card.Header style={{ textAlign: "center" }}>
            <h1>Search</h1>
          </Card.Header>
          <Card.Description>
            <Form.Input
              onChange={(event, text) => {
                setState({ ...state, name: text.value.toUpperCase() });
              }}
              label="First and Last Name"
              type="text"
              placeholder="First & Last Name"
              required
            />
            <Form.Input
              onChange={(event, text) => {
                setState({
                  ...state,
                  billingAddress: text.value.toUpperCase(),
                });
              }}
              label="Billing Address"
              type="text"
              placeholder="Enter Address associated with the bill"
            />
            <Form.Input
              onChange={(event, text) => {
                setState({
                  ...state,
                  serviceAddress: text.value.toUpperCase(),
                });
              }}
              label="Service Address"
              type="text"
              placeholder="Enter Address associated with the service location"
            />
            <Form.Input
              onChange={(event, text) => {
                setState({ ...state, phoneNumber: text.value });
              }}
              label="Phone Number"
              type="text"
              placeholder="Enter the Phone number associated with the bill"
            />
            <Form.Input
              onChange={(event, text) => {
                setState({ ...state, license: text.value.toUpperCase() });
              }}
              label="Driver's License Number"
              type="text"
              placeholder="Enter Drivers License Number"
            />
          </Card.Description>
          <Button
            type="submit"
            id="submit"
            primary
            style={{ marginTop: 10, borderRadius: 100 }}
          >
            Search
          </Button>
        </Card>
      </div>
    </Form>
  );
};
