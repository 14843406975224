import React, { useContext, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Menu, Image, Sidebar } from "semantic-ui-react";
import "./assets/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faDollarSign,
  faEnvelope,
  faBars,
  faMultiply,
} from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import logo from "./assets/Logo2-w.png";
import { NavLink, useNavigate } from "react-router-dom";
import { rootRoutes, routes } from "./routes";
import { useMedia } from "./breakpoints";
import { AuthContext } from "./auth/authContext";

export const Navigation = () => {
  const isTabletMin = useMedia("TabletMin");
  return (
    <div
      style={{
        height: 60 /* Adjust height according to your navbar's height */,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        width: "100%",
      }}
    >
      {isTabletMin ? <DesktopNavigation /> : <MobileNavigation />}
    </div>
  );
};

const DesktopNavigation = () => {
  const navigate = useNavigate();
  const { currentUser, signOut } = useContext(AuthContext); 
  return (
    <>
      <Menu
        className="usd-bar usd-blue-navBackground usd-card"
        style={{
          paddingTop: 0,
          backgroundColor: "#0b6c8c",
        }}
      >
        <Menu.Item
          as={NavLink}
          to={!currentUser ? rootRoutes.home : routes.authenticated.companyPage}
          className="usd-bar-item usd-transparent usd-border-white usd-button usd-wide"
          
        >  
          <Image
            style={{
              width: 80,
              backgroundColor: "white",
              borderRadius: 12,
              padding: 16,
            }}
            src={logo}
          />
        </Menu.Item>
        <i
            style={{
              padding: 16,
              borderRadius: 12,
              color: "white",
              fontSize: 20,
              justifyContent: "center",
              flex: 2

          }}> (318)702-7071 </i>
        {!currentUser ? (
          <Menu.Menu
            position="right"
            className="usd-right usd-hide-small"
            style={{ paddingTop: "4%" }}
          >
            
            <Menu.Item
              as={HashLink}
              to={`${rootRoutes.home}#about`}
              className="usd-bar-item usd-button"
              style={{ color: "white" }}
            >
              ABOUT
            </Menu.Item>
            <Menu.Item
              as={HashLink}
              to={`${rootRoutes.home}#pricing`}
              className="usd-bar-item usd-button"
              style={{ color: "white" }}
            >
              <FontAwesomeIcon
                icon={faDollarSign}
                style={{ paddingRight: 3 }}
              />
              PRICING
            </Menu.Item>
            <Menu.Item
              style={{ color: "white" }}
              as={NavLink}
              to={routes.home.companyLogin}
              className="usd-bar-item usd-button"
            >
              <FontAwesomeIcon icon={faBuilding} style={{ paddingRight: 3 }} />
              COMPANY
            </Menu.Item>
            <Menu.Item
              style={{ color: "white" }}
              as={NavLink}
              to={routes.home.clientPage}
            >
              <FontAwesomeIcon icon={faUser} style={{ paddingRight: 3 }} />
              CUSTOMER
            </Menu.Item>
            <Menu.Item
              as={HashLink}
              to={`${rootRoutes.home}#contact`}
              className="usd-bar-item usd-button"
              style={{ color: "white" }}
            >
              <FontAwesomeIcon icon={faEnvelope} style={{ paddingRight: 3 }} />
              CONTACT
            </Menu.Item>
          </Menu.Menu>
        ) : (
         
          <Menu.Menu
            position="right"
            className="usd-right usd-hide-small"
            style={{ paddingTop: "4%" }}
              >
            
            <Menu.Item
              as={HashLink}
              to={`${routes.authenticated.companyPage}#contact`}
              className="usd-bar-item usd-button"
              style={{ color: "white" }}
            >
              <FontAwesomeIcon icon={faEnvelope} style={{ paddingRight: 3 }} />
              CONTACT
            </Menu.Item>
            <Menu.Item
              className="usd-bar-item usd-button"
              style={{ color: "white" }}
              as={NavLink}
              to={routes.authenticated.profileSettings}
            >
              Settings
            </Menu.Item>
            <Menu.Item
              className="usd-bar-item usd-button"
              style={{ color: "white" }}
              onClick={() => {
                signOut();
                navigate(routes.home.companyLogin);
              }}
            >
              Log Out
            </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>
    </>
  );
};

const MobileNavigation = () => {
  const [visible, setVisible] = useState(false);
  const [icon, setIcon] = useState(<FontAwesomeIcon icon={faBars} />);
  const { currentUser, signOut } = useContext(AuthContext);

  const hideSidebar = () => {
    setIcon(<FontAwesomeIcon icon={faBars} />);
    setVisible(false);
  };
  const showSidebar = () => {
    setIcon(<FontAwesomeIcon icon={faMultiply} />);
    setVisible(true);
  };

  const toggleSidebar = () => {
    visible ? hideSidebar() : showSidebar();
  };

  return (
    <Menu
      className="usd-bar usd-blue-navBackground usd-card"
      style={{
        paddingTop: 0,
        backgroundColor: "#0b6c8c",
      }}
    >
      <Menu.Item
        as={NavLink}
        to={!currentUser ? rootRoutes.home : routes.authenticated.companyPage}
      >
        <Image
          style={{
            width: 80,
            backgroundColor: "white",
            borderRadius: 12,
            padding: 16,
          }}
          src={logo}
        />
        
      </Menu.Item>
      <i style={{
        padding: 16,
        borderRadius:12,
        color: "white",
        fontSize: 20,
        justifyContent: "center"
      }}
      > (318)702-7071 </i>
      <Menu.Menu position="right">
        <Menu.Item style={{ color: "white" }} onClick={toggleSidebar}>
          {icon}
        </Menu.Item>
      </Menu.Menu>
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        vertical
        visible={visible}
        onHide={() => {
          setVisible(false);
          toggleSidebar();
        }}
        width="thin"
        direction="right"
        style={{ backgroundColor: "#0b6c8c" }}
      >
        {!currentUser ? (
          <>
            <Menu.Item
              as={HashLink}
              to={`${rootRoutes.home}#about`}
              style={{ color: "white" }}
            >
              ABOUT
            </Menu.Item>
            <Menu.Item
              as={HashLink}
              to={`${rootRoutes.home}#pricing`}
              style={{ color: "white" }}
            >
              PRICING
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              to={routes.home.companyLogin}
              style={{ color: "white" }}
            >
              COMPANY
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              to={routes.home.clientPage}
              style={{ color: "white" }}
            >
              CUSTOMER
            </Menu.Item>
            <Menu.Item
              as={HashLink}
              to={`${rootRoutes.home}#contact`}
              style={{ color: "white" }}
            >
              CONTACT
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item
              as={HashLink}
              to={`${rootRoutes.home}#contact`}
              style={{ color: "white" }}
            >
              CONTACT
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              to={routes.authenticated.profileSettings}
              style={{ color: "white" }}
            >
              SETTINGS
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              to={routes.home.companyLogin}
              style={{ color: "white" }}
              onClick={() => {
                signOut();
                setVisible(false);
              }}
            >
              LOG OUT
            </Menu.Item>
          </>
        )}
      </Sidebar>
    </Menu>
  );
};
