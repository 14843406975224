import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  GridColumn,
  Input,
  Table,
  Modal,
} from "semantic-ui-react";
import { routes } from "../routes";
import { Link, useNavigate } from "react-router-dom";
import { faList, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "../auth/authContext";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { db } from "../auth/firebase";
import { useDropzone } from "react-dropzone";
import { CSVLink } from "react-csv";
import { CompanyRegistration } from "./companyRegistration";
export const AdminPage = () => {
  const [fileError, setFileErrors] = useState("");
  const { company } = useContext(AuthContext);
  const [companiesList, setCompaniesList] = useState<any[]>([]);
  const navigate = useNavigate();
  const [debt, setDebt] = useState(0);
  const [fileSuccess, setFileSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openAddCompanyModal, setOpenAddCompanyModal] = useState(false);
  const csvData = [
    [
      "ACCOUNT NO",
      "NAME",
      "MAILING ADDRESS",
      "SERVICE ADDRESS",
      "SSN",
      "TELEPHONE",
      "EMAIL",
      "STATUS",
      "DL#",
      "METER NO",
      "DEBT",
    ],
    [
      "123456",
      "SMITH, JOHN",
      "1234 Main St",
      "1234 Main St",
      "44-444-4444",
      "123-456-7890",
      "johnsmith@gmail.com",
      "ACTIVE",
      "12345678",
      "12345",
      "30",
    ],
  ];
  const [state, setState] = useState({
    name: "",
    phoneNumber: "",
    billingAddress: "",
    serviceAddress: "",
    license: "",
  });
  const [addCompanyState, setAddCompanyState] = useState({
    ID: "",
    Level: "",
    Location: "",
    Name: "",
    depositOwner: "",
    depositRenter: "",
    disconnectFee: "",
    disconnectFeesPaid: "",
    lateCharge: "",
    membershipFee: "",
    newConnectionFee: "",
    newMeterInstall: "",
    newServiceFeesPaid: "",
    returnedCheckFee: "",
    setUpFee: "",
  });
  const handleSubmit = () => {
    navigate(routes.home.clientSearchResults, { state: { data: state } });
  };
  const handleAddCompany = async () => {
    await setDoc(doc(db, "companies/", addCompanyState.ID), {
      ID: addCompanyState.ID,
      Level: addCompanyState.Level,
      Location: addCompanyState.Location,
      Name: addCompanyState.Name,
      depositOwner: addCompanyState.depositOwner,
      depositRenter: addCompanyState.depositRenter,
      disconnectFee: addCompanyState.disconnectFee,
      disconnectFeesPaid: addCompanyState.disconnectFeesPaid,
      lateCharge: addCompanyState.lateCharge,
      membershipFee: addCompanyState.membershipFee,
      newConnectionFee: addCompanyState.newConnectionFee,
      newMeterInstall: addCompanyState.newMeterInstall,
      newServiceFeesPaid: addCompanyState.newServiceFeesPaid,
      returnedCheckFee: addCompanyState.returnedCheckFee,
      setUpFee: addCompanyState.setUpFee,
    });
  };
  const headerRow =
    "ACCOUNTNO,NAME,MAILINGADDRESS,SERVICEADDRESS,SSN,TELEPHONE,EMAIL,STATUS,DL#,METERNO,DEBT";
  useEffect(() => {
    (async () => {
      let companylist: any[] = [];
      const companyIds: any[] = [];
      const querySnapshot = await getDocs(
        query(collection(db, "users"))
      );
      querySnapshot.forEach(async (snapshot) => {
        const companyId = snapshot.data().companyN;
        if (companyId !== "") {
          if (!companyIds.includes(companyId)) {
            companyIds.push(companyId);
            const companySnapshot = await getDoc(
              doc(db, "companies", companyId)
            );
            const companyName = companySnapshot.data()?.Name;

            companylist.push(companyName);
          }
        }
      });
      setCompaniesList(companylist);
      setLoading(false);
    })();
  }, []);
  return (
    <>
      <header className="bgimg-1 usd-display-container" id="home">
        <div style={{ width: "100%" }}>
          <div
            className="usd-display-right usd-text-white usd-center"
            style={{
              paddingTop: "15%",
              paddingBottom: "20%",
              marginLeft: "50%",
            }}
          >
            <span
              className="usd-jumbo  usd-text-white usd-hide-small"
              style={{ fontWeight: "bold", lineHeight: 1.4 }}
            >
              Welcome, ADMIN
            </span>
            <br />
            <span
              className="usd-large usd-text-white"
              style={{ fontWeight: "bold" }}
            >
              view your information below
            </span>
            <p>
              <Link
                to={routes.authenticated.profileSettings}
                className="usd-button usd-white usd-text-blue usd-padding-large usd-large usd-margin-top usd-opacity usd-hover-opacity-off"
              >
                Settings
              </Link>
            </p>
          </div>
        </div>
      </header>
      <Grid columns={2} style={{ margin: 10 }}>
        <GridColumn width={8}>
          <div style={{ overflowX: "scroll" }}>
            <Table
              className="usd-striped usd-bordered usd-table-all"
              id="clients"
            >
              <Table.Header>
                <Table.HeaderCell className="usd-green-background">
                  <h4 className="usd-text-white">Companies</h4>
                </Table.HeaderCell>
              </Table.Header>
              <Table.Body id="companiesList">
                {companiesList?.map((companyName) => {
                  return (
                    <Table.Row>
                      <Table.Cell>{companyName} </Table.Cell>
                    </Table.Row>
                  );
                })}
                <Table.Row id="totalCompaniesDebt">
                  <Table.Cell
                    className="usd-green-background usd-text-white"
                    id="totalDebt"
                  >
                    <h3>Total Debt</h3>
                    <Input
                      value={`$${debt > 0 ? debt.toFixed(2) : debt}`}
                      type="text"
                      id="totalDebtInput"
                      name="totalDebt"
                      className="usd-light-grey"
                      disabled
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row id="totalCompaniesMoneyBack">
                  <Table.Cell
                    className="usd-green-background usd-text-white"
                    id="totalBack"
                  >
                    <h3>Total Money Back</h3>
                    <Input
                      value={`$${ debt> 0 ? debt.toFixed(2) : debt}`}
                      type="text"
                      id="totalDebtInput"
                      name="totalDebt"
                      className="usd-light-grey"
                      disabled
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.Cell>
                    <Button
                      onClick={() => navigate(routes.authenticated.viewAllCompanies)}
                      style={{ background: "#0b6c8c" }}
                      className="usd-button usd-green-background usd-padding-large usd-text-white"
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ paddingRight: 5 }}
                      />
                      View All
                    </Button>
                    <Modal
                      open={openAddCompanyModal}
                      onOpen={() => setOpenAddCompanyModal(true)}
                      trigger={
                        <Button
                          style={{
                            backgroundColor: "#0b6c8c",
                            color: "white",
                            borderRadius: 12,
                          }}
                          className="usd-button usd-green-background usd-padding-large usd-text-white"
                        >
                          <FontAwesomeIcon
                            icon={faUser}
                            style={{ paddingRight: 5 }}
                          />
                          Create Company
                        </Button>
                      }
                    >
                      <Modal.Header
                        style={{ textAlign: "center" }}
                        className="usd-green-background usd-text-white"
                      >
                        Enter New Company Information
                        <br />
                      </Modal.Header>
                      <Modal.Content scrolling>
                        <Form id="addCompany" onSubmit={handleAddCompany}>
                          <div style={{ overflowX: "scroll" }}>
                            <Table
                              className="usd-striped usd-bordered usd-table-all"
                              id="addCompany"
                            >
                              <Table.Header>
                                <Table.HeaderCell
                                  style={{ backgroundColor: "#0b6c8c" }}
                                >
                                  <h4 className="usd-text-white">Add</h4>
                                </Table.HeaderCell>
                              </Table.Header>
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell style={{ display: "block" }}>
                                    <Form.Input
                                      type="text"
                                      label="ID"
                                      placeholder="ID"
                                      value={addCompanyState.ID}
                                      onChange={(event, value) => {
                                        setAddCompanyState({
                                          ...addCompanyState,
                                          ID: value?.value,
                                        });
                                      }}
                                      required
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      type="text"
                                      label="Level"
                                      value={addCompanyState.Level}
                                      onChange={(event, value) => {
                                        setAddCompanyState({
                                          ...addCompanyState,
                                          Level: value?.value.toUpperCase(),
                                        });
                                      }}
                                      placeholder="Level"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      type="text"
                                      label="Location"
                                      value={addCompanyState.Location}
                                      onChange={(event, value) => {
                                        setAddCompanyState({
                                          ...addCompanyState,
                                          Location: value?.value.toUpperCase(),
                                        });
                                      }}
                                      placeholder="Enter Location"
                                      required
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      type="text"
                                      label="Name"
                                      value={addCompanyState.Name}
                                      onChange={(event, value) => {
                                        setAddCompanyState({
                                          ...addCompanyState,
                                          Name: value?.value.toUpperCase(),
                                        });
                                      }}
                                      placeholder="Enter Name"
                                      required
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      value={addCompanyState.depositOwner}
                                      onChange={(event, value) => {
                                        setAddCompanyState({
                                          ...addCompanyState,
                                          depositOwner: value?.value,
                                        });
                                      }}
                                      type="text"
                                      placeholder="depositOwner"
                                      label="Deposit Owner"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      value={addCompanyState.depositRenter}
                                      onChange={(event, value) => {
                                        setAddCompanyState({
                                          ...addCompanyState,
                                          depositRenter: value?.value.toUpperCase(),
                                        });
                                      }}
                                      type="text"
                                      placeholder="Enter the renter deposit"
                                      label="Deposit Renter"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      value={addCompanyState.disconnectFee}
                                      onChange={(event, value) => {
                                        setAddCompanyState({
                                          ...addCompanyState,
                                          disconnectFee: value?.value.toUpperCase(),
                                        });
                                      }}
                                      type="text"
                                      placeholder="Enter the disconnect Fees"
                                      label="Disconnect Fee"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      value={addCompanyState.lateCharge}
                                      onChange={(event, value) => {
                                        setAddCompanyState({
                                          ...addCompanyState,
                                          lateCharge: value?.value.toUpperCase(),
                                        });
                                      }}
                                      type="text"
                                      placeholder="lateCharge"
                                      label="Late Charge Fee"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      value={addCompanyState.membershipFee}
                                      onChange={(event, value) => {
                                        setAddCompanyState({
                                          ...addCompanyState,
                                          membershipFee: value?.value,
                                        });
                                      }}
                                      type="text"
                                      placeholder="Enter membership fee"
                                      label="Membership Fee"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      value={addCompanyState.newConnectionFee}
                                      onChange={(event, value) => {
                                        setAddCompanyState({
                                          ...addCompanyState,
                                          newConnectionFee: value?.value,
                                        });
                                      }}
                                      type="text"
                                      placeholder="Enter the new Connection Fee"
                                      label="New Connection Fee"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      value={addCompanyState.newMeterInstall}
                                      onChange={(event, value) => {
                                        setAddCompanyState({
                                          ...addCompanyState,
                                          newMeterInstall: value?.value,
                                        });
                                      }}
                                      type="text"
                                      placeholder="Enter the new Meter Install "
                                      label="New Meter Install"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      value={addCompanyState.returnedCheckFee}
                                      onChange={(event, value) => {
                                        setAddCompanyState({
                                          ...addCompanyState,
                                          returnedCheckFee: value?.value,
                                        });
                                      }}
                                      type="text"
                                      placeholder="Enter the Returned Check Fee"
                                      label="Returned Check Fee"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      value={addCompanyState.setUpFee}
                                      onChange={(event, value) => {
                                        setAddCompanyState({
                                          ...addCompanyState,
                                          setUpFee: value?.value,
                                        });
                                      }}
                                      type="text"
                                      placeholder="Enter the set up Fee"
                                      label="Set Up Fee"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                              <Table.Footer>
                                <Table.Cell>
                                  <Button
                                    onClick={() => {
                                      setOpenAddCompanyModal(false);
                                    }}
                                    style={{
                                      color: "white",
                                      borderRadius: 10,
                                      background: "#0b6c8c",
                                    }}
                                    className="usd-centered usd-button  usd-padding-large"
                                  >
                                    Add
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setOpenAddCompanyModal(false);
                                    }}
                                    style={{
                                      color: "white",
                                      borderRadius: 10,
                                      background: "#0b6c8c",
                                    }}
                                    className="usd-centered usd-button  usd-padding-large"
                                  >
                                    Cancel
                                  </Button>
                                </Table.Cell>
                              </Table.Footer>
                            </Table>
                          </div>
                        </Form>
                      </Modal.Content>
                    </Modal>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </div>
          <div style={{ overflowX: "scroll" }}>
            <Table
              className="usd-striped usd-bordered usd-table-all usd-blue-background"
              id="clients"
            >
              <Table.Header className="usd-blue-background">
                <Table.HeaderCell style={{ backgroundColor: "#0b6c8c" }}>
                  <h4 className="usd-text-white">PAID</h4>
                </Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell
                    className="usd-blue-background usd-text-white"
                    id="total"
                  >
                    <h3>Total Paid</h3>
                    <Input
                      value={
                        company?.debtPaid > 0
                          ? `$${company?.debtPaid?.toFixed(2)}`
                          : "$0"
                      }
                      type="text"
                      disabled
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.Cell>
                    <Link
                      to=""
                      className="usd-button usd-blue-background usd-padding-large usd-text-white"
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ paddingRight: 5 }}
                      />
                      View All
                    </Link>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </div>
        </GridColumn>
        <GridColumn width={8}>

          <Modal
            open={open}
            onOpen={() => setOpen(true)}
            trigger={
              <Button
                style={{ background: "#719229" }}
                className="usd-button usd-blue-background usd-padding-large usd-text-white"
              >
                <FontAwesomeIcon icon={faUser} style={{ paddingRight: 5 }} />
                Client Search
              </Button>
            }
          >
            <Modal.Header
              style={{ textAlign: "center" }}
              className="usd-blue-background usd-text-white"
            >
              Search For Exisiting Client
              <br />
            </Modal.Header>
            <Modal.Content scrolling>
              <Form id="customers" onSubmit={handleSubmit}>
                <div style={{ overflowX: "scroll" }}>
                  <Table
                    className="usd-striped usd-bordered usd-table-all"
                    id="search"
                  >
                    <Table.Header>
                      <Table.HeaderCell style={{ backgroundColor: "#0b6c8c" }}>
                        <h4 className="usd-text-white">Search</h4>
                      </Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell style={{ display: "block" }}>
                          <Form.Input
                            type="text"
                            label="Name"
                            placeholder="First & Last Name"
                            onChange={(event, value) => {
                              setState({
                                ...state,
                                name: value?.value.toUpperCase(),
                              });
                            }}
                            required
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Form.Input
                            type="text"
                            label="Billing Address"
                            onChange={(event, value) => {
                              setState({
                                ...state,
                                billingAddress: value?.value.toUpperCase(),
                              });
                            }}
                            placeholder="Enter Address associated with the bill"
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Form.Input
                            type="text"
                            label="Service Address"
                            onChange={(event, value) => {
                              setState({
                                ...state,
                                serviceAddress: value?.value.toUpperCase(),
                              });
                            }}
                            placeholder="Enter Address associated with the service location"
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Form.Input
                            onChange={(event, value) => {
                              setState({ ...state, phoneNumber: value?.value });
                            }}
                            type="text"
                            placeholder="Enter the Phone number associated with the bill"
                            label="Phone Number"
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Form.Input
                            onChange={(event, value) => {
                              setState({
                                ...state,
                                license: value?.value.toUpperCase(),
                              });
                            }}
                            type="text"
                            placeholder="Enter Drivers License Number"
                            label="Driver's License Number"
                          />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                    <Table.Footer>
                      <Table.Cell>
                        <Button
                          type="submit"
                          id="submit"
                          style={{
                            backgroundColor: "#0b6c8c",
                            color: "white",
                            borderRadius: 12,
                          }}
                          className="usd-centered usd-button usd-blue-background usd-padding-large"
                        >
                          Search
                        </Button>
                        <Button
                          onClick={() => {
                            setOpen(false);
                          }}
                          style={{
                            color: "white",
                            borderRadius: 10,
                            background: "#0b6c8c",
                          }}
                          className="usd-centered usd-button  usd-padding-large"
                        >
                          Cancel
                        </Button>
                      </Table.Cell>
                    </Table.Footer>
                  </Table>
                </div>
              </Form>
            </Modal.Content>
          </Modal>
          <div style={{ overflowX: "scroll" }}>
            <Table className="usd-striped usd-bordered usd-table-all">
              <Table.Header>
                <Table.HeaderCell className="usd-green-background">
                  <h4 className="usd-text-white">Upload File Here</h4>
                </Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                <Table.Row id="file" className="usd-green-background">
                  <Table.Cell>
                    <Form id="upload-file">
                      <span style={{ color: "white" }}>Use </span>
                      <CSVLink data={csvData} style={{ color: "#0b6c8c" }}>
                        this template
                      </CSVLink>
                      <span style={{ color: "white" }}>
                        to upload customers to the database.
                      </span>
                      {fileError && (
                        <div style={{ color: "red" }}>{fileError}</div>
                      )}
                      {fileSuccess && (
                        <div style={{ color: "green" }}>{fileSuccess}</div>
                      )}
                     
                    </Form>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
         <section
        className="usd-container usd-center"
        style={{ padding: "70px 16px" }}
      >
        <div >
         <button  style={{ background: "#719229" }}
                className="usd-button usd-blue-background usd-padding-large usd-text-white"
                onClick={() => navigate(routes.home.companyRegistration)}> 
          Company Registration
          </button>
        </div>
      </section>
        </GridColumn>
      </Grid>
    </>
  );
};
