export const rootRoutes = {
  home: "/",
  authenticated: "/company",
};

export const routes = {
  home: {
    paymentForm:`${rootRoutes.home}paymentForm`,
    companyLogin: `${rootRoutes.home}login`,
    clientSearch: `${rootRoutes.home}search`,
    clientSearchResults: `${rootRoutes.home}search/results`,
    clientPage: `${rootRoutes.home}clientPage`,
    clientNewServices: `${rootRoutes.home}newServices`,
    clientNewServicesSearch: `${rootRoutes.home}newServicesSearch`,
    companyRegistration: `${rootRoutes.home}registration/:id`,
  },
  authenticated: {
    companyPage: `${rootRoutes.authenticated}`,
    profileSettings: `${rootRoutes.authenticated}/profile-settings`,
    admin: `${rootRoutes.authenticated}/admin`,
    viewAllNew: `${rootRoutes.authenticated}/viewAll/new`,
    viewAllBadDebt: `${rootRoutes.authenticated}/viewAll/bad-debt`,
    viewAllPaid: `${rootRoutes.authenticated}/viewAll/paid`,
    viewAllCompanies: `${rootRoutes.authenticated}/viewAll/companies`,
  },
};
