import React, { useEffect } from "react";
import { Image, Grid } from "semantic-ui-react";
import clientBillPayLogo from "./assets/ClientBillPay.png";
import clientNewServicesLogo from "./assets/ClientNewServicesLogo.png";
import companyLoginLogo from "./assets/CompanyLogIn Logo.png";
import { Link } from "react-router-dom";
import { routes } from "./routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faGem, faHeart } from "@fortawesome/free-solid-svg-icons";
import { useMedia } from "./breakpoints";
import { ContactForm } from "./contact";
 //import StripePricingTable from "./company/pricingTable"; // Commented out to remove other pricing tables

export const Home = () => {
  const isTabletMin = useMedia("TabletMin");

  return (
    <>
      <section style={{ paddingTop: "6%" }}>
        <div
          className="bgimg-1 usd-display-container usd-text-white usd-center"
          id="home"
        >
          <Grid columns={2}>
            <Grid.Column></Grid.Column>
            <Grid.Column>
              <div
                style={
                  isTabletMin
                    ? {
                        float: "right",
                        marginTop: "12%",
                        marginRight: "20%",
                        paddingRight: "10%",
                      }
                    : { justifyContent: "center" }
                }
              >
                <div
                  style={
                    isTabletMin ? { paddingTop: "0" } : { paddingTop: "15%" }
                  }
                >
                  <h1 style={{ fontSize: "4em", paddingTop: "18%" }}>
                    <strong> Utility Service Database</strong>
                  </h1>
                  <p style={{ fontSize: "2em" }}>
                    Customer Review & Collections
                  </p>
                  <Link
                    to="#about"
                    className="usd-button usd-white usd-text-blue usd-padding-large usd-medium usd-margin-top usd-opacity usd-hover-opacity-off"
                    style={{ marginBottom: 15, fontSize: "2em" }}
                  >
                    ABOUT THE COMPANY
                  </Link>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </section>

      <section
        className="usd-container usd-light-grey"
        style={{ padding: "80px 16px" }}
        id="about"
      >
        <h2 className="usd-center">ABOUT THE COMPANY</h2>
        <p className="usd-center usd-large">Key features of our company</p>
        <div className="usd-row-padding usd-center" style={{ marginTop: 64 }}>
          <div className="usd-third usd-padding">
            <FontAwesomeIcon
              icon={faDesktop}
              style={{
                width: 50,
                height: 50,
                paddingBottom: 15,
                color: "#0b6c8c",
              }}
            />
            <p className="usd-large ">Services</p>
            <p>
              Utility Service Database (USD) provides a database system designed
              specifically for utility companies to verify if their customers
              owe money to other utility companies. Our company is designed to
              speed up the verification process, reduce the likelihood of unpaid
              debts going unnoticed and reduce financial risks for utility
              companies.
            </p>
          </div>
          <div className="usd-third usd-padding">
            <FontAwesomeIcon
              icon={faHeart}
              style={{
                width: 50,
                height: 50,
                paddingBottom: 15,
                color: "#719229",
              }}
            />
            <p className="usd-large">Mission</p>
            <p>
              USD's mission is to identify unpaid debt that may influence a
              utility company's decision to provide service to a client and to
              assist utility companies in debt collection. USD does not charge
              the utility company for collection assistance.
            </p>
          </div>
          <div className="usd-third usd-padding">
            <FontAwesomeIcon
              icon={faGem}
              style={{
                width: 50,
                height: 50,
                paddingBottom: 15,
                color: "#0b6c8c",
              }}
            />
            <p className="usd-large">How it works</p>
            <p>
              When a utility company signs up for USD services, they give USD a
              list of their client's information. We then query our database,
              which contains records of unpaid debts from other utility
              companies. If any discrepancies are found, we will generate a
              report detailing the up paid debts and the utility company they
              belong to. The utility company will refer the client to USD to
              clear these debts.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div
          className="usd-container usd-center"
          style={{ padding: "16px" }}
          id="services"
        >
          <h2 className="usd-center">OUR SERVICES</h2>
          <Grid doubling columns={3}>
            <Grid.Column>
              <Link to={routes.home.clientSearch} className="usd-button">
                <Image src={clientBillPayLogo} alt="billPayment" />
              </Link>
              <h4>Customer: Pay Bill</h4>
            </Grid.Column>
            <Grid.Column>
              <Link
                to={routes.home.clientNewServices}
                className="usd-button"
              >
                <Image src={clientNewServicesLogo} alt="newService" />
              </Link>
              <h4>Customer: New Services</h4>
            </Grid.Column>
            <Grid.Column>
              <Link to="./login" className="usd-button">
                <Image src={companyLoginLogo} alt="company" />
              </Link>
              <h4>Company: Log In</h4>
            </Grid.Column>
          </Grid>
        </div>
        <div id="modal01" className="usd-modal usd-black">
          <span
            className="usd-button usd-xxlarge usd-black usd-padding-large usd-display-topright"
            title="Close Modal Image"
          >
            ×
          </span>
          <div className="usd-modal-content usd-animate-zoom usd-center usd-transparent usd-padding-64">
            <img id="img01" className="usd-image" />
            <p id="caption" className="usd-opacity usd-large"></p>
          </div>
        </div>
      </section>
      <section
        className="usd-container usd-light-grey"
        style={{ padding: "0 16px", paddingBottom: "10%" }}
        id="contact"
      >
        <ContactForm />
      </section>
    </>
  );
};

// const TestStripePricingTable = () => {
//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = "https://js.stripe.com/v3/pricing-table.js";
//     script.async = true;
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return React.createElement("stripe-pricing-table", {
//     "pricing-table-id": "prctbl_1N9BD5ErIu1GQCHNwdUKG1nY",
//     "publishable-key":
//       "pk_test_51MrixsErIu1GQCHNBQurAGOx6BBSY3N1edpVyl4uQJT6yr4B4kSpPzX4KQ7hMWDzErYsUseGv1VFR8QDsT3qASue008quCxQru",
//   });
// };
