import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Pagination,
  Popup,
  Modal,
  Form,
  Button,
  Loader,
} from "semantic-ui-react";
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../auth/firebase";
import { AuthContext } from "../auth/authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEye, faPencil } from "@fortawesome/free-solid-svg-icons";
import { validatePhoneNumber } from "../helpers/validation";
import moment from "moment";
import { Statuses } from "../enums";

export const ViewAllPaid = () => {
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 25;
  const [open, setOpen] = useState(false);
  const [canEdit, setCanEdit] = useState<boolean>();
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const { company } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [allInfo, setAllInfo] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const startIndex = (activePage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    const totalInformation = async () => {
      if (company?.ID) {
        const query1 = query(
          collection(db, "clients"),
          where("CompanyId", "==", company.ID),
          where("Status", "==", Statuses.Paid)
        );
        const query2 = getDocs(query1);
        const [result] = await Promise.all([query2]);

        let clientList: any[] = [];
        result.docs.forEach((x) => {
          const clientData = x.data();
          const clientId = x.id;
          const clientWithId = { ...clientData, id: clientId };
          clientList.push(clientWithId);
        });
        clientList = clientList.sort((a, b) =>
          a.CustomerName > b.CustomerName ? 1 : -1
        );
        const visibleData = clientList.slice(startIndex, endIndex);

        setAllInfo(visibleData);
        setTotalPages(Math.ceil(clientList.length / itemsPerPage));
      }
    };

    totalInformation();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.ID, startIndex, endIndex]);

  const handlePageChange = (event: any, { activePage }: any) => {
    setActivePage(activePage);
  };
  if (loading) {
    return <Loader active />;
  }
  return (
    <div style={{ overflowX: "scroll", margin: "1rem" }}>
      <Table
        className="usd-striped usd-bordered usd-table-all usd-padding-105"
        id="clients"
      >
        <Table.Header>
          <Table.HeaderCell
            className="usd-green-background  usd-padding-16"
            style={{ width: "10%" }}
          ></Table.HeaderCell>
          <Table.HeaderCell
            style={{ width: "20%" }}
            className="usd-green-background  usd-padding-16"
          >
            <h4 className="usd-text-white">ACCOUNT NUMBER</h4>
          </Table.HeaderCell>
          <Table.HeaderCell
            style={{ width: "40%" }}
            className="usd-green-background  usd-padding-16"
          >
            <h4 className="usd-text-white">NAME</h4>
          </Table.HeaderCell>
          <Table.HeaderCell className="usd-green-background  usd-padding-16">
            <h4 className="usd-text-white">PAID DATE</h4>
          </Table.HeaderCell>
          <Table.HeaderCell className="usd-green-background  usd-padding-16">
            <h4 className="usd-text-white">DEBT AMOUNT</h4>
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body id="viewAll">
          {allInfo?.map((client) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Popup
                    content="Edit Customer"
                    trigger={
                      <FontAwesomeIcon
                        icon={faPencil}
                        style={{ padding: "0 0.5em" }}
                        onClick={() => {
                          setOpen(true);
                          setCanEdit(true);
                          setSelectedCustomerId(client.id);
                        }}
                      />
                    }
                  />
                  <Popup
                    content="View Customer"
                    trigger={
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{ padding: "0 0.5em" }}
                        onClick={() => {
                          setOpen(true);
                          setCanEdit(false);
                          setSelectedCustomerId(client.id);
                        }}
                      />
                    }
                  />
                  <Popup
                    content="Email Customer"
                    trigger={
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ padding: "0 0.5em" }}
                      />
                    }
                  />
                </Table.Cell>
                <Table.Cell style={{ width: "20%" }}>
                  {client.AccountNumber}
                </Table.Cell>
                <Table.Cell>{client.CustomerName}</Table.Cell>
                <Table.Cell>{moment(client.paidDate).format("lll")}</Table.Cell>
                <Table.Cell>{client.debtPaid}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Cell colSpan="4">
              <Pagination
                activePage={activePage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
      {open && (
        <CustomerModal
          onClose={() => setOpen(false)}
          isEditable={canEdit}
          customerId={selectedCustomerId}
        />
      )}
    </div>
  );
};

interface ModalProps {
  isEditable?: boolean;
  onClose: () => void;
  customerId: string;
}
const CustomerModal: React.FC<ModalProps> = ({
  isEditable,
  onClose,
  customerId,
}) => {
  const [customerData, setCustomerData] = useState<DocumentData | undefined>(
    undefined
  );
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    const getCustomer = async () => {
      await getDoc(doc(db, "clients", customerId)).then(async (user) => {
        const userData = user.data();
        setCustomerData(userData);
      });
    };
    getCustomer();
  }, [customerId]);

  const handleSave = async () => {
    setError("");
    const validPhoneNumber = validatePhoneNumber(customerData?.phoneNumber);
    if (!validPhoneNumber) {
      setError("Please enter a valid phone number");
    }
    await updateDoc(doc(db, "clients", customerId), {
      customerData,
    });
    console.log(customerId);
    setIsOpen(false);
  };
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>
        {isEditable ? "EDIT " : "VIEW "}
        {customerData?.CustomerName}
      </Modal.Header>
      <Modal.Content>
        <Form>
          {error && <h6 color="red">{error}</h6>}
          <Form.Input
            style={{ opacity: "3.0" }}
            label="Account Number"
            name="accountNumber"
            value={customerData?.AccountNumber}
            disabled
          />
          <Form.Input
            style={{ opacity: "3.0" }}
            label="Customer Name"
            name="customerName"
            value={customerData?.CustomerName}
            disabled
          />
          <Form.Input
            style={{ opacity: "3.0" }}
            label="Email"
            name="email"
            value={customerData?.email}
            disabled={!isEditable}
            onChange={(event, value) => {}}
          />
          <Form.Group widths="equal">
            <Form.Input
              style={{ opacity: "3.0" }}
              label="Drivers License"
              name="driversLicense"
              value={customerData?.DriversLicense}
              disabled
            />
            <Form.Input
              style={{ opacity: "3.0" }}
              label="Phone Number"
              name="phoneNumber"
              value={customerData?.phoneNumber}
              disabled={!isEditable}
              onChange={(event, value) => {
                setCustomerData({
                  ...customerData,
                  phoneNumber: value.value,
                });
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              style={{ opacity: "3.0" }}
              label="Billing Address"
              name="billingAddress"
              value={customerData?.billingAddress}
              disabled={!isEditable}
              onChange={(event, value) => {
                setCustomerData({
                  ...customerData,
                  billingAddress: value.value.toUpperCase(),
                });
              }}
            />
            <Form.Input
              style={{ opacity: "3.0" }}
              label="Service Address"
              name="serviceAddress"
              value={customerData?.serviceAddress}
              disabled
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              style={{ opacity: "3.0" }}
              label="Meter Number"
              name="meterNumber"
              value={customerData?.meterNumber}
              disabled
            />
            <Form.Input
              style={{ opacity: "3.0" }}
              label="Debt"
              name="debt"
              value={`${customerData?.debt}`}
              disabled={!isEditable}
              onChange={(event, value) => {
                setCustomerData({
                  ...customerData,
                  debt: value.value,
                });
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {isEditable && (
          <Button primary onClick={handleSave}>
            Save
          </Button>
        )}
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};
