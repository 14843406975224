import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Form, Header, Icon } from "semantic-ui-react";
import { signInUser } from "../auth/firebase";
import { rootRoutes, routes } from "../routes";
import { NavHashLink } from "react-router-hash-link";
import { AuthContext } from "../auth/authContext";

export const CompanyLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [state, setState] = useState({ email: "", password: "" });
  const { currentUserData } = useContext(AuthContext);

  const handleSubmit = () => {
    setErrorMessage("");
    signInUser(state.email, state.password)
      .then(async () => {
        if (currentUserData) {
          if (currentUserData.role === "admin") {
            navigate(routes.authenticated.admin);
            console.log(currentUserData.role);
          } else if (currentUserData.role === "user") {
            navigate("/company");  // Redirect to /company
          }
        }
      })
      .catch((e) => {
        const error = e as any;
        switch (error.code) {
          case "auth/wrong-password":
            setErrorMessage("Incorrect Password");
            break;
          case "auth/user-not-found":
            setErrorMessage("Account not found");
            break;
          default:
            console.log(error);
        }
      });
  };
  

  return (
    <div
      className="usd-container usd-light-grey"
      style={{ padding: "128px 16px" }}
      id="companyLogIn"
    >
      <Card style={{ width: 400, height: 400, padding: 25, margin: "auto" }}>
        <Card.Header style={{ textAlign: "center" }}>
          <Header as="h1">Log-In</Header>
          <Header as="h4" style={{ marginTop: 0, marginBottom: 10 }}>
            Log in to see your options
          </Header>
        </Card.Header>
        <Form onSubmit={handleSubmit}>
          <Card.Description style={{ marginBottom: 10 }}>
            {errors && <h4 color="red">{errors}</h4>}
            <Form.Input
              label="Email"
              name="email"
              value={state.email}
              placeholder="Enter email"
              onChange={(event, value) => {
                setState({ ...state, email: value.value });
              }}
              required
            />
            <Form.Input
              type={showPassword ? "text" : "password"}
              label="Password"
              name="password"
              defaultValue={state.password}
              // value={state.password}
              onChange={(event, value) => {
                setState({ ...state, password: value.value });
              }}
              placeholder="Enter Password"
              required
              icon={
                !showPassword ? (
                  <Icon
                    name="eye"
                    link
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <Icon name="eye slash" />
                )
              }
            />
          </Card.Description>
          <div>
            <Form.Button
              type="submit"
              style={{
                fontWeight: "bold",
                backgroundColor: "#719229",
                color: "white",
              }}
              id="submit"
            >
              Submit
            </Form.Button>
            <NavHashLink
              to={`${rootRoutes.home}#pricing`}
              style={{
                fontWeight: "bold",
              }}
            >
              Don't have an account? Sign up!
            </NavHashLink>
          </div>
        </Form>
      </Card>
    </div>
  );
};
