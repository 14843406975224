import { useLocation, useNavigate } from "react-router-dom";
import { rootRoutes } from ".";
import { useContext, useEffect } from "react";
import { AuthContext } from "../auth/authContext";
// import { useContext } from "react";
// import { AuthContext } from "../auth/authContext";

function RequireAuth({ children }: { children: JSX.Element }) {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    if (!currentUser) {
      navigate(rootRoutes.home);
    }
  }, [currentUser, navigate, location.pathname]);
  return children;
}

export default RequireAuth;
