import React, { ReactNode, createContext, useEffect, useState } from "react";
import { User } from "firebase/auth";
import { SignOutUser, db, userStateListener } from "./firebase";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes";
import { DocumentData, doc, getDoc } from "firebase/firestore";

interface Props {
  children?: ReactNode;
}

export const AuthContext = createContext({
  // "User" comes from firebase auth-public.d.ts
  currentUser: {} as User | null,
  currentUserData: {} as DocumentData | undefined,
  setCurrentUserData: (user: DocumentData) => {},
  setCurrentUser: (_user: User) => {},
  setCompany: (company: DocumentData) => {},
  company: {} as DocumentData | undefined,
  signOut: () => {},
});

export const AuthProvider = ({ children }: Props) => {
  const [currentUser, setCurrentUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem("currentUser");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [company, setCompany] = useState<DocumentData | undefined>(undefined);
  const navigate = useNavigate();
  const [currentUserData, setCurrentUserData] = useState<
    DocumentData | undefined
  >(undefined);
  useEffect(() => {
    const getUser = userStateListener(async (user) => {
      if (user) {
      setCurrentUser(user);
      localStorage.setItem("currentUser", JSON.stringify(user));
      await getDoc(doc(db, "users", user.uid)).then(async (user) => {
        setCurrentUserData(user.data());
        if (user?.data()?.companyN !== "") {
          await getDoc(doc(db, "companies/", user?.data()?.companyN)).then(
            (company) => {
              setCompany(company.data());
            
            }
          );
        
  

    } else {
      setCompany(undefined);
    }

    });
  }
});
 return getUser;
  },[setCurrentUser]);
  
  // As soon as setting the current user to null,
  // the user will be redirected to the home page.
  const signOut = () => {
    SignOutUser();
    setCurrentUser(null);
    setCompany(undefined);
    setCurrentUserData(undefined);
    localStorage.removeItem("currentUser");
    navigate(routes.home.companyLogin);
  };

  const value = {
    currentUser,
    setCurrentUser,
    setCurrentUserData,
    currentUserData,
    company,
    setCompany,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
