import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Pagination,
  Popup,
  Modal,
  Form,
  Button,
  Loader,
} from "semantic-ui-react";
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
    updateDoc,
  orderBy,
  where,
} from "firebase/firestore";
import { routes } from "../routes";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../auth/firebase";
import { AuthContext } from "../auth/authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEye, faPencil, faPrint } from "@fortawesome/free-solid-svg-icons";
import { validatePhoneNumber } from "../helpers/validation";
import moment from "moment";
import { Statuses } from "../enums";
/// <reference types="jspdf" />
import jsPDF from 'jspdf';

export const ViewAllCompanies = () => {
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 25;
  const [open, setOpen] = useState(false);
  const [canEdit, setCanEdit] = useState<boolean>();
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const { company } = useContext(AuthContext);
  const [companiesList, setCompaniesList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [allInfo, setAllInfo] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const startIndex = (activePage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      let companylist: any[] = [];
      const companyIds: any[] = [];
      const querySnapshot = await getDocs(
        query(collection(db, "users"), orderBy("companyN"))
      );
      querySnapshot.forEach(async (snapshot) => {
        const companyId = snapshot.data().companyN;
        if (companyId !== "") {
          if (!companyIds.includes(companyId)) {
            companyIds.push(companyId);
              const companySnapshot = await getDoc(doc(db, "companies", companyId));
              
            const companyName = companySnapshot.data();
            companylist.push(companyName);
          }
        }
      });
        
      setCompaniesList(companylist);
      setLoading(false);
    })();
  }, []);
  const handlePageChange = (event: any, { activePage }: any) => {
    setActivePage(activePage);
  };
  const generatePDF = (data: any) => {
    const doc = new jsPDF();
    const lineHeight = 5;
    let verticalPosition = 5;
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text("Name: ", 5, verticalPosition+=lineHeight*2);
    doc.text(`${company?.Name}`, 10, verticalPosition+=lineHeight*2);
    doc.text("ID: ", 5, verticalPosition+=lineHeight);
    doc.text(`${company?.ID}`, 10, verticalPosition+=lineHeight*2);
    doc.text("Debt Paid: ", 5, verticalPosition+=lineHeight);
    doc.text(`${company?.debtPaid}`,10, verticalPosition += lineHeight*2); 
    doc.text("Disconnect Fees Paid: ", 5, verticalPosition+=lineHeight);
    doc.text(`${company?.disconnectFeesPaid}`,10, verticalPosition += lineHeight*2);
    doc.text("New Service Fees Paid:  ", 5, verticalPosition+=lineHeight);
    doc.text(`${company?.newServiceFeesPaid}`, 10, verticalPosition += lineHeight*2);
    doc.text("Number of Clients: ", 5, verticalPosition += lineHeight);
    doc.text(`${company?.numClients}`, 10, verticalPosition += lineHeight*2);
    doc.text("Owner Deposit: ", 5, verticalPosition += lineHeight);
    doc.text(`${company?.depositOwner}`, 10, verticalPosition += lineHeight*2);
    doc.text("Renter Deposit: ", 5, verticalPosition += lineHeight);
    doc.text(`${company?.depositRenter}`, 10, verticalPosition += lineHeight*2);
    doc.text("Set Up Fee: ", 5, verticalPosition += lineHeight);
    doc.text(`${company?.setUpFee}`, 10, verticalPosition += lineHeight*2);
    doc.text("Membership Fee: ", 5, verticalPosition += lineHeight);
    doc.text(`${company?.membershipFee}`, 10, verticalPosition += lineHeight*2);
    doc.text("New Connection Fee: ", 5, verticalPosition += lineHeight);
    doc.text(`${company?.newConnectionFee}`, 10, verticalPosition += lineHeight*2);
    doc.text("New Meter Install: ", 5, verticalPosition += lineHeight);
    doc.text(`${company?.newMeterInstall}`, 10, verticalPosition += lineHeight*2);
    doc.text("Returned Check Fee: ", 5, verticalPosition += lineHeight);
    doc.text(`${company?.returnedCheckFee}`, 10, verticalPosition += lineHeight*2);
    doc.text("Disconnect Fee: ", 5, verticalPosition += lineHeight);
    doc.text(`${company?.disconnectFee}`, 10, verticalPosition += lineHeight*2);
    doc.text("Late Charge: ", 5, verticalPosition += lineHeight);
    doc.text(`${company?.lateCharge}`, 10, verticalPosition += lineHeight*2);
    
    
    doc.save(`${company?.Name}.pdf`)
  };
  if (loading) {
    return <Loader active />;
  }
  return (
    <div style={{ overflowX: "scroll", margin: "1rem" }}>
      <Table
        className="usd-striped usd-bordered usd-table-all usd-padding-105"
        id="companies"
        style = {{width:"100%"}}
      >
        <Table.Header>
          <Table.HeaderCell
            className="usd-green-background  usd-padding-16"
            style={{ width: "10%" }}
          ></Table.HeaderCell>
          <Table.HeaderCell
            style={{ width: "40%" }}
            className="usd-green-background  usd-padding-16"
          ><h4 className="usd-text-white">Company ID</h4>
          </Table.HeaderCell>
          <Table.HeaderCell
            style={{ width: "40%" }}
            className="usd-green-background  usd-padding-16"
          ><h4 className="usd-text-white">NAME</h4>
          </Table.HeaderCell>
          <Table.HeaderCell
            style={{ width: "40%" }}
            className="usd-green-background  usd-padding-16"
          ><h4 className="usd-text-white">New Services Fees Paid</h4>
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body id="viewAll">
          {companiesList?.map((companyName) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Popup
                    content="Edit Company"
                    trigger={
                      <FontAwesomeIcon
                        icon={faPencil}
                        style={{ padding: "0 0.5em" }}
                        onClick={() => {
                          setOpen(true);
                          setCanEdit(true);
                          setSelectedCompanyId(companyName?.ID);
                        }}
                      />
                    }
                  />
                  <Popup
                    content="View Company"
                    trigger={
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{ padding: "0 0.5em" }}
                        onClick={() => {
                          setOpen(true);
                          setCanEdit(false);
                          setSelectedCompanyId(companyName?.ID);
                        }}
                      />
                    }
                  />
                  <Popup
                    content="Email Company"
                    trigger={
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ padding: "0 0.5em" }}
                      />

                    }
                  />
                  <Popup 
                    content="PDF"
                    trigger={
                      <FontAwesomeIcon 
                        icon={faPrint}
                        style={{ padding: "0 0.5em" }}
                        onClick={() => {
                          setSelectedCompanyId(companyName?.ID)
                          generatePDF(setSelectedCompanyId)
                        }}
                      />
                  }
                  />
                </Table.Cell>
                <Table.Cell>{companyName?.ID}</Table.Cell>
                <Table.Cell>{companyName?.Name}</Table.Cell>
                <Table.Cell>{companyName?.newServiceFeesPaid}</Table.Cell>
              </Table.Row>
              
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Cell colSpan="4">
              <Pagination
                activePage={activePage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
      {open && (
        <CompanyModal
          onClose={() => setOpen(false)}
          
          isEditable={canEdit}
          companyId={selectedCompanyId}
        />
      )}
    </div>
  );
};

interface ModalProps {
  isEditable?: boolean;
  onClose: () => void;
  companyId: string;
  
}
const CompanyModal: React.FC<ModalProps> = ({
  isEditable,
  onClose,
  companyId,
  
}) => {
  const [companyData, setCompanyData] = useState<DocumentData | undefined>(
    undefined
  );
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    const getCompany = async () => {
      await getDoc(doc(db, "companies", companyId)).then(async (user) => {
        const userData = user.data();
        setCompanyData(userData);
      });
    };
    getCompany();
  }, [companyId]);

  const handleSave = async () => {
    setError("");
    const validPhoneNumber = validatePhoneNumber(companyData?.phoneNumber);
    if (!validPhoneNumber) {
      setError("Please enter a valid phone number");
    }
    await updateDoc(doc(db, "companies", companyId), {
      companyData,
    });
    setIsOpen(false);
  };
  return (
    <Modal open={isOpen} onClose={onClose} style={{width:"50%"}}>
      <Modal.Header>
        {isEditable ? "EDIT " : "VIEW "}
        
        {companyData?.Name}
      </Modal.Header>
      <Modal.Content>
        <Form>
          {error && <h6 color="red">{error}</h6>}
          <Form.Input
            style={{ opacity: "3.0" }}
            label="Company ID"
            name="companyId"
            value={companyData?.ID}
            disabled
          />
          <Form.Input
            style={{ opacity: "3.0" }}
            label="Company Name"
            name="companyName"
            value={companyData?.Name}
           disabled
          />
          <Form.Input
            style={{ opacity: "3.0" }}
            label="paid"
            name="paid"
            value={companyData?.newServiceFeesPaid}
            disabled
                  />
        <Form.Input
            style={{ opacity: "5.0" }}
            label="total # of Clients"
            name="totalClients"
            value={companyData?.numClients}
          />
          <Form.Input
            style={{ opacity: "5.0" }}
            label="Total Disconnect Fees Paid"
            name="totalDisconnectFeesPaid"
            value={companyData?.disconnectFeesPaid}
          />      
          <Form.Input
            style={{ opacity: "5.0" }}
            label="Comapany's Fees (depositOwner/Renter)"
            name="despoistOwner"
            value={companyData?.depositOwner}
            disabled={!isEditable}
            onChange={(event, value) => { }}
          />
          <Form.Input
            style={{ opacity: "5.0" }}
            label="Comapany's Fees (depositRenter)"
            name="despoistOwner"
            value={companyData?.depositRenter}
            disabled={!isEditable}
            onChange={(event, value) => { }}
          />
          <Form.Input
            style={{ opacity: "5.0" }}
            label="disconnect Fee"
            name="disconnectFee"
            value={companyData?.disconnectFee}
            disabled={!isEditable}
            onChange={(event, value) => { }}
          /> 
          <Form.Input
            style={{ opacity: "5.0" }}
            label="Late Charge"
            name="lateCharge"
            value={companyData?.lateCharge}
            disabled={!isEditable}
            onChange={(event, value) => { }}
          /> 
          <Form.Input
            style={{ opacity: "5.0" }}
            label="Membership Fee"
            name="membershipFee"
            value={companyData?.membershipFee}
            disabled={!isEditable}
            onChange={(event, value) => { }}
          />
          <Form.Input
            style={{ opacity: "5.0" }}
            label="New Connection Fee"
            name="newConnectionFee"
            value={companyData?.newConnectionFee}
            disabled={!isEditable}
            onChange={(event, value) => { }} /> 
          <Form.Input
            style={{ opacity: "5.0" }}
            label="New Meter Install Fee"
            name="newMeterInstall"
            value={companyData?.newMeterInstall}
            disabled={!isEditable}
            onChange={(event, value) => { }}
          />
          <Form.Input
            style={{ opacity: "5.0" }}
            label="Returned Check Fee"
            name="returnedCheckFee"
            value={companyData?.returnedCheckFee}
            disabled={!isEditable}
            onChange={(event, value) => { }}
          /> 
          <Form.Input
            style={{ opacity: "5.0" }}
            label="SetUp Fee"
            name="setUpFee"
            value={companyData?.setUpFee}
            disabled={!isEditable}
            onChange={(event, value) => { }}
          /> 
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {isEditable && (
          <Button primary onClick={handleSave}>
            Save
          </Button>
        )}
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};
