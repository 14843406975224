import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Pagination,
  Popup,
  Modal,
  Form,
  Button,
  Loader,
} from "semantic-ui-react";
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../auth/firebase";
import { AuthContext } from "../auth/authContext";
import { Statuses } from "../enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEye, faPencil,faPrint } from "@fortawesome/free-solid-svg-icons";
import { validatePhoneNumber } from "../helpers/validation";
import moment from "moment";
/// <reference types="jspdf" />
import jsPDF from 'jspdf';

export const ViewAllNew = () => {
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 25;
  const [open, setOpen] = useState(false);
  const [canEdit, setCanEdit] = useState<boolean>();
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const { company } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [allInfo, setAllInfo] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const startIndex = (activePage - 1) * itemsPerPage;
  const [customerData, setCustomerData] = useState<DocumentData | undefined>(
    undefined
  );
  const endIndex = startIndex + itemsPerPage;
  useEffect(() => {
    const totalInformation = async () => {
      if (company?.ID) {
        const query1 = query(
          collection(db, "clients"),
          where("CompanyId", "==", company.ID),
          where("Status", "==", Statuses.New)
        );
        const query2 = getDocs(query1);
        const [result] = await Promise.all([query2]);

        let clientList: any[] = [];
        result.docs.forEach((x) => {
          const clientData = x.data();
          const clientId = x.id;
          const clientWithId = { ...clientData, id: clientId };
          clientList.push(clientWithId);
        });
        clientList = clientList.sort((a, b) =>
          a.date < b.date ? 1 : -1
        );
        const visibleData = clientList.slice(startIndex, endIndex);

        setAllInfo(visibleData);
        setTotalPages(Math.ceil(clientList.length / itemsPerPage));
      }
    };

    totalInformation();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.ID, startIndex, endIndex]);

  const handlePageChange = (event: any, { activePage }: any) => {
    setActivePage(activePage);
  };
  useEffect(() => {
    const getCustomer = async () => {
      await getDoc(doc(db, "clients", selectedCustomerId)).then(async (user) => {
        const userData = user.data();
        setCustomerData(userData);
      });
    };
    getCustomer();
  }, [selectedCustomerId]);
  const generatePDF = (data: any) => {
    const doc = new jsPDF();
    const lineHeight = 5;
    let verticalPosition = 5;
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text("Customer Name", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.CustomerName}`, 10, verticalPosition += lineHeight * 2);
    doc.text("Company ID", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.CompanyId}`, 10, verticalPosition += lineHeight * 2);
    doc.text("Drivers License", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.DriversLicense}`, 10, verticalPosition += lineHeight * 2);
    doc.text("Billing Address", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.billingAddress}`, 10, verticalPosition += lineHeight * 2);
    doc.text("Email", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.email}`, 10, verticalPosition += lineHeight * 2);
    doc.text("Phone Number", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.phoneNumber}`, 10, verticalPosition += lineHeight * 2);
    doc.text("Service Address", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.serviceAddress}`, 10, verticalPosition += lineHeight * 2);
    doc.text("Property Type", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.PropertyType}`, 10, verticalPosition += lineHeight * 2);
    doc.text("New Meter Install?", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.NewMeterInstall}`, 10, verticalPosition += lineHeight * 2);
    doc.text("Created Date", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.date}`, 10, verticalPosition += lineHeight * 2);
    doc.text("Terms and Conditons?", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.TermsAndConditions}`, 10, verticalPosition += lineHeight * 2);
    doc.text("Date of Birth", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.dob}`, 10, verticalPosition += lineHeight * 2);
    doc.text("Social", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.ssn}`, 10, verticalPosition += lineHeight * 2);
    doc.text("Renter?", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.renter}`, 10, verticalPosition += lineHeight * 2);
    doc.text("Electronic Signature", 5, verticalPosition += lineHeight * 2);
    doc.text(`${customerData?.electronicSig}`, 10, verticalPosition += lineHeight * 2);
    doc.save(`${customerData?.CustomerName}.pdf`)
  }
  if (loading) {
    return <Loader active />;
  }

  return (
    <div style={{ overflowX: "scroll", margin: "1rem" }}>
      <Table
        className="usd-striped usd-bordered usd-table-all usd-padding-105"
        id="clients"
        style={{ width: "100%" }}
      >
        <Table.Header>
          <Table.HeaderCell
            className="usd-green-background  usd-padding-16"
            style={{ width: "10%" }}
          ></Table.HeaderCell>
          <Table.HeaderCell
            style={{ width: "40%" }}
            className="usd-green-background  usd-padding-16"
          >
            <h4 className="usd-text-white">NAME</h4>
          </Table.HeaderCell>
          <Table.HeaderCell className="usd-green-background  usd-padding-16">
            <h4 className="usd-text-white">Created Date</h4>
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body id="viewAll">
          {allInfo?.map((client) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Popup
                    content="Edit Customer"
                    trigger={
                      <FontAwesomeIcon
                        icon={faPencil}
                        style={{ padding: "0 0.5em" }}
                        onClick={() => {
                          setOpen(true);
                          setCanEdit(true);
                          setSelectedCustomerId(client.id);
                        }}
                      />
                    }
                  />
                  <Popup
                    content="View Customer"
                    trigger={
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{ padding: "0 0.5em"}}
                        onClick={() => {
                          setOpen(true);
                          setCanEdit(false);
                          setSelectedCustomerId(client.id);
                        }}
                      />
                    }
                  />
                  <Popup
                    content="Email Customer"
                    trigger={
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ padding: "0 0.5em" }}
                      />
                    }
                  />
                  <Popup 
                    content="PDF"
                    trigger={
                      <FontAwesomeIcon 
                        icon={faPrint}
                        style={{ padding: "0 0.5em" }}
                        onClick={() => {
                          setSelectedCustomerId(client.id);
                          generatePDF(setSelectedCustomerId(client.id));
                        }}
                        
                      />
                  }
                  />
                </Table.Cell>
                <Table.Cell>{client.CustomerName}</Table.Cell>
                <Table.Cell>{moment(client.date).format("lll") ?? "None"}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Cell colSpan="3">
              <Pagination
                activePage={activePage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
      {open && (
        <CustomerModal
          onClose={() => setOpen(false)}
          isEditable={canEdit}
          customerId={selectedCustomerId}
        />
      )}
    </div>
  );
};

interface ModalProps {
  isEditable?: boolean;
  onClose: () => void;
  customerId: string;
}
const CustomerModal: React.FC<ModalProps> = ({
  isEditable,
  onClose,
  customerId,
}) => {
  const [customerData, setCustomerData] = useState<DocumentData | undefined>(
    undefined
  );
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    const getCustomer = async () => {
      await getDoc(doc(db, "clients", customerId)).then(async (user) => {
        const userData = user.data();
        setCustomerData(userData);
      });
    };
    getCustomer();
  }, [customerId]);
 
  const handleSave = async () => {
    setError("");
    const validPhoneNumber = validatePhoneNumber(customerData?.phoneNumber);
    if (!validPhoneNumber) {
      setError("Please enter a valid phone number");
    }
    await updateDoc(doc(db, "clients", customerId), {
      customerData,
    });
    console.log(customerId);
    setIsOpen(false);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>
        {isEditable ? "EDIT " : "VIEW "}
        {customerData?.CustomerName}
      </Modal.Header>
      <Modal.Content>
        <Form>
          {error && <h6 color="red">{error}</h6>}
          {(customerData?.Status === Statuses.BadDebt ||
            customerData?.Status === Statuses.Disconnect) && (
            <Form.Input
              style={{ opacity: "0", color: "black" }}
              label="Account Number"
              name="accountNumber"
              value={customerData?.AccountNumber}
              disabled
            />
          )}
          <Form.Input
            style={{ opacity: 2, color: "black" }}
            label="Customer Name"
            name="customerName"
            value={customerData?.CustomerName}
            disabled
          />
          <Form.Input
            style={{ opacity: "1.0" }}
            label="Email"
            name="email"
            value={customerData?.email}
            disabled={!isEditable}
            onChange={(event, value) => {}}
          />
          <Form.Group widths="equal">
            <Form.Input
              style={{ opacity: "1.0" }}
              label="Drivers License"
              name="driversLicense"
              value={customerData?.DriversLicense}
              disabled
            />
            <Form.Input
              style={{ opacity: "1.0" }}
              label="Phone Number"
              name="phoneNumber"
              value={customerData?.phoneNumber}
              disabled={!isEditable}
              onChange={(event, value) => {
                setCustomerData({
                  ...customerData,
                  phoneNumber: value.value,
                });
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              style={{ opacity: "1.0" }}
              label="Billing Address"
              name="billingAddress"
              value={customerData?.billingAddress}
              disabled={!isEditable}
              onChange={(event, value) => {
                setCustomerData({
                  ...customerData,
                  billingAddress: value.value.toUpperCase(),
                });
              }}
            />
            <Form.Input
              style={{ opacity: "1.0" }}
              label="Service Address"
              name="serviceAddress"
              value={customerData?.serviceAddress}
              disabled
            />
          </Form.Group>
          {(customerData?.Status === Statuses.BadDebt ||
            customerData?.Status === Statuses.Disconnect) && (
            <Form.Group widths="equal">
              <Form.Input
                style={{ opacity: "1.0" }}
                label="Meter Number"
                name="meterNumber"
                value={customerData?.meterNumber}
                disabled
              />
              <Form.Input
                style={{ opacity: "1.0" }}
                label="Debt"
                name="debt"
                value={`${customerData?.debt}`}
                disabled={!isEditable}
                onChange={(event, value) => {
                  setCustomerData({
                    ...customerData,
                    debt: value.value,
                  });
                }}
              />
            </Form.Group>
          )}
          {customerData?.Status === Statuses.New && (
            <>
              <Form.Input
                style={{ opacity: "1.0"}}
                label="Property Type"
                name="propertyType"
                value={customerData?.PropertyType}
                disabled
              />
              <Form.Input
                style={{ opacity: "1.0" }}
                label="Created Date"
                name="createdDate"
                value={moment(customerData?.date).format("lll")}
                disabled
              />
            </>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {isEditable && (
          <Button primary onClick={handleSave}>
            Save
          </Button>
        )}
        <Button onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};
