import {
  faEnvelope,
  faLocationDot,
  faPaperPlane,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import axios from 'axios';

export const ContactForm = () => {
  const [state, setState] = useState({
    from: 'info@utilityservicedatabase.com',
    to: 'info@utilityservicedatabase.com',
    subject: '',
    name:'',
    text: '',
    senderEmail : ''
  });

  const handleSubmit = async () => {
    try {
      const constructedSubject = `${state.subject} | from ${state.name} <${state.senderEmail}>`;
      const payload = {
        from: state.from,
        to: state.to,
        subject: constructedSubject,
        text: state.text
      };
      console.log('Sending payload:', payload); 
      const response = await axios.post('https://us-central1-utility-service-databases.cloudfunctions.net/sendEmail', payload);

      alert('Email sent successfully');
    } catch (error) {
      console.error('Error sending email: ', error);
      alert('Error sending email');
    }
  };

  return (
    <>
      <h2 className="usd-center">CONTACT</h2>
      <p className="usd-center usd-large">
        Lets get in touch. Send us a message:
      </p>
      <div style={{ marginTop: 48 }}>
        <p>
          <FontAwesomeIcon
            icon={faLocationDot}
            style={{ width: 30, height: 30, paddingRight: 5 }}
          />
          Louisiana, US
        </p>
        <p>
          <FontAwesomeIcon
            icon={faPhone}
            style={{ width: 30, height: 30, paddingRight: 5 }}
          />
          Phone: 318-702-7071
        </p>
        <p>
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{ width: 30, height: 30, paddingRight: 5 }}
          />
          Email: info@utilityservicedatabase.com
        </p>
        <br />
        <Form onSubmit={handleSubmit}>
          <Form.Input
            type="text"
            placeholder="Name"
            id="name"
            required
            onChange={(event, value) => {
              setState({ ...state, name: value.value });
            }}
          />
          <Form.Input
            type="email"
            placeholder="Email"
            id="senderEmail"
            required
            onChange={(event, value) => {
              setState({ ...state, senderEmail: value.value });
            }}
          />
          <Form.Input
            type="text"
            placeholder="Subject"
            id="subject"
            required
            onChange={(event, value) => {
              setState({ ...state, subject: value.value });
            }}
          />
          <Form.TextArea
            placeholder="Message"
            required
            type="text"
            onChange={(event, value) => {
              setState({
                ...state,
                text: value.value ? value.value?.toString() : "",
              });
            }}
          ></Form.TextArea>
          <Button className="usd-button usd-black" type="submit">
            <FontAwesomeIcon icon={faPaperPlane} /> SEND MESSAGE
          </Button>
        </Form>
      </div>
    </>
  );
};
export default ContactForm;