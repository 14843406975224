import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  GridColumn,
  Input,
  Loader,
  Table,
  Modal,
  Card,
  Header,
} from "semantic-ui-react";
import { routes } from "../routes";
import { Link, useNavigate } from "react-router-dom";
import { faList, faUpload, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "../auth/authContext";
import {
  DocumentData,
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "../auth/firebase";
import { useDropzone } from "react-dropzone";
import { CSVLink } from "react-csv";
import { ContactForm } from "../contact";
import { Statuses } from "../enums";

export const CompanyPage = () => {
  const [fileError, setFileErrors] = useState("");
  const [unpaidClientList, setUnpaidClientList] = useState<any[]>([]);
  const [paidClientList, setPaidClientList] = useState<any[]>([]);
  const [newClientList, setNewClientList] = useState<any[]>([]);
  const {
    company,
    currentUserData,
    currentUser,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [debt, setDebt] = useState(0);
  const [open, setOpen] = useState(false);
  const [fileSuccess, setFileSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const csvData = [
    [
      "ACCOUNT NO",
      "NAME",
      "MAILING ADDRESS",
      "SERVICE ADDRESS",
      "TELEPHONE",
      "EMAIL",
      "STATUS",
      "DL#",
      "METER NO",
      "DEBT",
    ],
    [
      "123456",
      "SMITH, JOHN",
      "1234 Main St",
      "1234 Main St",
      "123-456-7890",
      "johnsmith@gmail.com",
      "ACTIVE",
      "12345678",
      "12345",
      "30",
    ],
  ];
  const [state, setState] = useState({
    name: "",
    phoneNumber: "",
    billingAddress: "",
    serviceAddress: "",
    license: "",
  });

  const [addClientState, setAddClientState] = useState({
    AccountNumber: "",
    CustomerName: "",
    billingAddress: "",
    serviceAddress: "",
    phoneNumber: "",
    email: "",
    Status: "",
    DriversLicense: "",
    meterNumber: "",
    debt: "",
    CompanyId: company?.ID,
    debtAmount: 0,
    usdFee: 0,
  });
  const [numClients, setNumClients] = useState(currentUserData?.numClients);
  const status = [
    {
      text: "Bad Debt",
      value: Statuses.BadDebt,
    },
    {
      text: "Disconnected",
      value: Statuses.Disconnect,
    },
  ];
  const handleSubmit = () => {
    navigate(routes.home.clientSearchResults, { state: { data: state } });
  };

  const handleAddClient = async () => {
    await addDoc(collection(db, "clients"), {
      accountNumber: addClientState.AccountNumber,
      CustomerName: addClientState.CustomerName,
      billingAddress: addClientState.billingAddress,
      serviceAddress: addClientState.serviceAddress,
      phoneNumber: addClientState.phoneNumber,
      email: addClientState.email,
      Status: addClientState.Status,
      DriversLicense: addClientState.DriversLicense,
      meterNumber: addClientState.meterNumber,
      debt: addClientState.debt,
      CompanyId: addClientState.CompanyId,
      debtAmount: addClientState.debtAmount,
      usdFee: addClientState.usdFee,
    });
  };
  const headerRow =
    "ACCOUNTNO,NAME,MAILINGADDRESS,SERVICEADDRESS,TELEPHONE,EMAIL,STATUS,DL#,METERNO,DEBT";
  const { getRootProps, getInputProps } = useDropzone({
    accept: { "text/csv": [".csv"] },
    maxFiles: 1,
    onDrop: async ([file]) => {
      setFileErrors("");
      setLoading(true);
      setFileSuccess("");
      var reader = new FileReader();
      reader.onload = async function (e) {
        if (typeof e.target?.result !== "string") {
          throw new Error("Unexpected result from FileReader");
        } else {
          setLoading(true);
          var rows = e.target?.result.split("\n");
          rows[0] = rows[0].replace(/"/g, "");
          rows[0] = rows[0].replace(/\r/g, "");
          rows[0] = rows[0].replace(/ /g, "");
          if (rows[0] !== headerRow) {
            setFileErrors("This file does not match the template provided.");
          } else {
            // Collect unique identifiers from the rows you want to check
            const uniqueIdentifiers = rows
              .slice(1) // Exclude header row
              .map((row) => {
                const cells = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
                if (cells[1]) {
                  return {
                    CustomerName: cells[1].toUpperCase(),
                    billingAddress: cells[2].toUpperCase(),
                    debt: cells[10],
                    email: cells[6].toUpperCase(),
                    phoneNumber: cells[5],
                    serviceAddress: cells[3].toUpperCase(),
                  };
                }
                return null;
              });
            // Create an array to store existing documents
            const existingDocuments: DocumentData[] = [];
            // Batch the queries
            // eslint-disable-next-line array-callback-return
            const queryPromises = uniqueIdentifiers.map((identifier) => {
              if (identifier) {
                console.log(identifier);
                const querySnapshot = getDocs(
                  query(
                    collection(db, "clients"),
                    where("CustomerName", "==", identifier.CustomerName),
                    where("billingAddress", "==", identifier.billingAddress),
                    where("email", "==", identifier.email),
                    where("phoneNumber", "==", identifier.phoneNumber),
                    where("serviceAddress", "==", identifier.serviceAddress)
                  )
                );
                return querySnapshot.then((snapshot) => {
                  if (!snapshot.empty) {
                    // If a document already exists, add it to the existingDocuments array
                    existingDocuments.push(snapshot.docs[0].data());
                  }
                });
              }
            });

            // Wait for all queries to complete
            await Promise.all(queryPromises);
            const batchSize = 500;
            const rowBatches = [];
            for (let i = 1; i < rows.length; i += batchSize) {
              const batch = rows.slice(i, i + batchSize);
              rowBatches.push(batch);
            }

            // Process each row batch
            for (const batch of rowBatches) {
              // Create a batched write object
              const writebatch = writeBatch(db);
              // Iterate through the rows and add new documents if they don't already exist
              batch.forEach((row) => {
                var cells = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
                if (cells.length > 1) {
                  if (cells[1].includes(",")) {
                    const flippedName = cells[1]
                      .split(",") // Split the name at the comma
                      .map((namePart) => namePart.trim()) // Trim whitespace around name parts
                      .reverse() // Reverse the order of name parts
                      .join(" ")
                      .replace(/"([^"]+)"/g, "$1");
                    cells[1] = flippedName;
                  }
                  const exists = existingDocuments.some((document) => {
                    return (
                      document.CustomerName === cells[1].toUpperCase().trim() &&
                      document.billingAddress === cells[2].toUpperCase().trim() &&
                      document.debt === cells[10] &&
                      document.email === cells[6].toUpperCase().trim() &&
                      document.phoneNumber === cells[5].trim() &&
                      document.serviceAddress === cells[3].toUpperCase().trim()
                    );
                  });
                  if (!exists) {
                    // Add the new document to the batch
                    const docRef = doc(collection(db, "clients"));
                    writebatch.set(docRef, {
                      AccountNumber: cells[0].toUpperCase().trim(),
                      CustomerName: cells[1].toUpperCase().trim(),
                      billingAddress: cells[2].toUpperCase().trim(),
                      serviceAddress: cells[3].toUpperCase().trim(),
                      phoneNumber: cells[4].trim(),
                      email: cells[5].toUpperCase().trim(),
                      Status: cells[6].toUpperCase().trim(),
                      DriversLicense: cells[7].trim(),
                      meterNumber: cells[8].trim(),
                      debt: cells[9],
                      CompanyId: company?.ID,
                      debtAmount: 0,
                      usdFee: 0,
                    });
                    setNumClients(numClients + 1);
                  }
                }
              });
              // Commit the batched writes
              await writebatch.commit();
              if (currentUser) {
                const userDocRef = doc(db, "users/", currentUser.uid);
                await updateDoc(userDocRef, { numClients: numClients });
              }
              setLoading(false);
            }
            setFileSuccess("Upload Successful");
            setFileErrors("");
          }
        }
      };
      reader.readAsText(file);
    },
  });
  useEffect(() => {
    const allUnpaidClients = async () => {
      if (company?.ID) {
        await getDocs(
          query(
            collection(db, "clients"),
            where("CompanyId", "==", company.ID),
            where("debt", ">", "0")
          )
        ).then((clients) => {
          let clientlist: any[] = [];
          var debt = 0;
          clients.docs.forEach((x) => {
            debt += Number(x.data().debt);
            clientlist.push(x.data());
          });
          setDebt(debt);
          clientlist = clientlist
            .sort((a, b) => (a.CustomerName > b.CustomerName ? 1 : -1))
            .slice(0, 6);
          setUnpaidClientList(clientlist);
        });
      }
    };
    const allPaidClients = async () => {
      if (company?.ID) {
        await getDocs(
          query(
            collection(db, "clients"),
            where("CompanyId", "==", company.ID),
            where("Status", "==", Statuses.Paid)
          )
        ).then((clients) => {
          let paidClientlist: any[] = [];

          clients.docs.forEach((x) => {
            console.log(x.data());
            paidClientlist.push(x.data());
          });
          paidClientlist = paidClientlist
            .sort((a, b) => (a.CustomerName > b.CustomerName ? 1 : -1))
            .slice(0, 6);
          setPaidClientList(paidClientlist);
        });
      }
    };
    const allNewClients = async () => {
      if (company?.ID) {
        await getDocs(
          query(
            collection(db, "clients"),
            where("CompanyId", "==", company.ID),
            where("Status", "==", Statuses.New)
          )
        ).then((clients) => {
          let clientlist: any[] = [];
          clients.docs.forEach((x) => {
            clientlist.push(x.data());
          });
          clientlist = clientlist
            .sort((a, b) => (a.CustomerName > b.CustomerName ? 1 : -1))
            .slice(0, 6);
          setNewClientList(clientlist);
        });
      }
    };
    allUnpaidClients();
    allPaidClients();
    allNewClients();
    setLoading(false);
  }, [company]);

  if (loading) {
    return <Loader active />;
  }

  return (
    <>
      {loading && <Loader active />}
      <header className="bgimg-1 usd-display-container" id="home">
        <div style={{ width: "100%" }}>
          <div
            className="usd-display-right usd-text-white usd-center"
            style={{
              paddingTop: "15%",
              paddingBottom: "20%",
              marginLeft: "50%",
            }}
          >
            <span
              className="usd-jumbo  usd-text-white usd-hide-small"
              style={{ fontWeight: "bold", lineHeight: 1.4 }}
            >
              Welcome, {company?.Name}
            </span>
            <br />
            <span
              className="usd-large usd-text-white"
              style={{ fontWeight: "bold" }}
            >
              view your information below
            </span>
            <p>
              <Link
                to={routes.authenticated.profileSettings}
                className="usd-button usd-white usd-text-blue usd-padding-large usd-large usd-margin-top usd-opacity usd-hover-opacity-off"
              >
                Settings
              </Link>
            </p>
          </div>
        </div>
      </header>
      <Grid columns={2} style={{ margin: 10 }}>
        <GridColumn width={8}>
          <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            <Card
              style={{
                margin: "1rem",
                padding: "20px 20px 20px !important",
                textAlign: "center",
              }}
            >
              <Header
                style={{ margin: "10px 0 0 !important", padding: "1rem" }}
                size="large"
              >
                <Header.Content>
                  {currentUserData?.numClients}
                </Header.Content>
                <Header.Subheader>Total # of Clients</Header.Subheader>
              </Header>
            </Card>
            <Card
              style={{
                margin: "1rem",
                padding: "20px 20px 20px !important",
                textAlign: "center",
              }}
            >
              <Header
                style={{ margin: "10px 0 0 !important", padding: "1rem" }}
                size="large"
              >
                <Header.Content>${company?.newServiceFeesPaid}</Header.Content>
                <Header.Subheader>Total New Service Fees Paid</Header.Subheader>
              </Header>
            </Card>
          </div>
          <div style={{ overflowX: "scroll" }}>
            <Table
              className="usd-striped usd-bordered usd-table-all"
              id="clients"
            >
              <Table.Header>
                <Table.HeaderCell className="usd-green-background">
                  <h4 className="usd-text-white">DEBT</h4>
                </Table.HeaderCell>
              </Table.Header>
              <Table.Body id="clientsList">
                {unpaidClientList?.map((client) => {
                  return (
                    <Table.Row>
                      <Table.Cell>{client.CustomerName}</Table.Cell>
                    </Table.Row>
                  );
                })}
                <Table.Row id="totalDebtRow">
                  <Table.Cell
                    className="usd-green-background usd-text-white"
                    id="total"
                  >
                    <h3>Total Debt</h3>
                    <Input
                      value={`$${debt > 0 ? debt.toFixed(2) : debt}`}
                      type="text"
                      id="totalDebtInput"
                      name="totalDebt"
                      className="usd-light-grey"
                      disabled
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.Cell>
                    <Button
                      onClick={() =>
                        navigate(routes.authenticated.viewAllBadDebt)
                      }
                      style={{ background: "#719229" }}
                      className="usd-button usd-blue-background usd-padding-large usd-text-white"
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ paddingRight: 5 }}
                      />
                      View All
                    </Button>
                    <Modal
                      open={open}
                      onOpen={() => setOpen(true)}
                      trigger={
                        <Button
                          style={{ background: "#719229" }}
                          className="usd-button usd-blue-background usd-padding-large usd-text-white"
                        >
                          <FontAwesomeIcon
                            icon={faUser}
                            style={{ paddingRight: 5 }}
                          />
                          Add Client
                        </Button>
                      }
                    >
                      <Modal.Header
                        style={{ textAlign: "center" }}
                        className="usd-green-background usd-text-white"
                      >
                        Enter Bad Debt Client
                        <br />
                      </Modal.Header>
                      <Modal.Content scrolling>
                        <Form id="addClient" onSubmit={handleAddClient}>
                          <div style={{ overflowX: "scroll" }}>
                            <Table
                              className="usd-striped usd-bordered usd-table-all"
                              id="addClient"
                            >
                              <Table.Header>
                                <Table.HeaderCell
                                  style={{ backgroundColor: "#0b6c8c" }}
                                >
                                  <h4 className="usd-text-white">Add</h4>
                                </Table.HeaderCell>
                              </Table.Header>
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell style={{ display: "block" }}>
                                    <Form.Input
                                      type="text"
                                      label="AccountNumber"
                                      placeholder="Account Number"
                                      value={addClientState.AccountNumber}
                                      onChange={(event, value) => {
                                        setAddClientState({
                                          ...addClientState,
                                          AccountNumber: value?.value,
                                        });
                                      }}
                                      required
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      type="text"
                                      label="Customer Name"
                                      value={addClientState.CustomerName}
                                      onChange={(event, value) => {
                                        setAddClientState({
                                          ...addClientState,
                                          CustomerName: value?.value.toUpperCase(),
                                        });
                                      }}
                                      placeholder="Customer's First and Last Name"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      type="text"
                                      label="Billing Address"
                                      value={addClientState.billingAddress}
                                      onChange={(event, value) => {
                                        setAddClientState({
                                          ...addClientState,
                                          billingAddress: value?.value.toUpperCase(),
                                        });
                                      }}
                                      placeholder="Enter Billing Address"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      type="text"
                                      label="Service Address"
                                      value={addClientState.serviceAddress}
                                      onChange={(event, value) => {
                                        setAddClientState({
                                          ...addClientState,
                                          serviceAddress: value?.value.toUpperCase(),
                                        });
                                      }}
                                      placeholder="Enter Address associated with the service location"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      value={addClientState.phoneNumber}
                                      onChange={(event, value) => {
                                        setAddClientState({
                                          ...addClientState,
                                          phoneNumber: value?.value,
                                        });
                                      }}
                                      type="text"
                                      placeholder="Enter the Phone number associated with the bill"
                                      label="Phone Number"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      value={addClientState.email}
                                      onChange={(event, value) => {
                                        setAddClientState({
                                          ...addClientState,
                                          email: value?.value.toUpperCase(),
                                        });
                                      }}
                                      type="text"
                                      placeholder="Enter the email associated with the customer"
                                      label="Email"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Dropdown
                                      selection
                                      options={status}
                                      onChange={(event, value) => {
                                        if (value.value) {
                                          setAddClientState({
                                            ...addClientState,
                                            Status: value.value?.toString(),
                                          });
                                        }
                                      }}
                                      placeholder="Select the status of the customer"
                                      defaultValue=""
                                      required
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      value={addClientState.DriversLicense}
                                      onChange={(event, value) => {
                                        setState({
                                          ...state,
                                          license: value?.value,
                                        });
                                      }}
                                      type="text"
                                      placeholder="Enter Drivers License Number"
                                      label="Driver's License Number"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      value={addClientState.meterNumber}
                                      onChange={(event, value) => {
                                        setAddClientState({
                                          ...addClientState,
                                          meterNumber: value?.value,
                                        });
                                      }}
                                      type="text"
                                      placeholder="Enter Meter Number"
                                      label="Meter Number"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <Form.Input
                                      value={addClientState.debt}
                                      onChange={(event, value) => {
                                        setAddClientState({
                                          ...addClientState,
                                          debt: value?.value,
                                        });
                                      }}
                                      type="text"
                                      placeholder="Enter the Amount of Debt Owed"
                                      label="Debt"
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                              <Table.Footer>
                                <Table.Cell>
                                  <Button
                                    type="submit"
                                    onClick={() => {
                                      setOpen(false);
                                      handleAddClient();
                                    }}
                                    style={{
                                      color: "white",
                                      borderRadius: 10,
                                      background: "#0b6c8c",
                                    }}
                                    className="usd-centered usd-button  usd-padding-large"
                                  >
                                    Add
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                    style={{
                                      color: "white",
                                      borderRadius: 10,
                                      background: "#0b6c8c",
                                    }}
                                    className="usd-centered usd-button  usd-padding-large"
                                  >
                                    Cancel
                                  </Button>
                                </Table.Cell>
                              </Table.Footer>
                            </Table>
                          </div>
                        </Form>
                      </Modal.Content>
                    </Modal>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </div>
          <div style={{ overflowX: "scroll" }}>
            <Table
              className="usd-striped usd-bordered usd-table-all usd-blue-background"
              id="clients"
            >
              <Table.Header className="usd-blue-background">
                <Table.HeaderCell style={{ backgroundColor: "#0b6c8c" }}>
                  <h4 className="usd-text-white">NEW CLIENTS</h4>
                </Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {newClientList?.map((client) => {
                  return (
                    <Table.Row>
                      <Table.Cell>{client.CustomerName}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.Cell>
                    <Button
                      onClick={() => navigate(routes.authenticated.viewAllNew)}
                      style={{ background: "#0b6c8c" }}
                      className="usd-button usd-blue-background usd-padding-large usd-text-white"
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ paddingRight: 5 }}
                      />
                      View All
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </div>
        </GridColumn>
        <GridColumn width={8}>
          <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            <Card
              style={{
                margin: "1rem",
                padding: "20px 20px 20px !important",
                textAlign: "center",
              }}
            >
              <Header
                style={{ margin: "10px 0 0 !important", padding: "1rem" }}
                size="large"
              >
                <Header.Content>${company?.disconnectFeesPaid}</Header.Content>
                <Header.Subheader>Total Disconnect Fees Paid</Header.Subheader>
              </Header>
            </Card>
            <Card
              style={{
                margin: "1rem",
                padding: "20px 20px 20px !important",
                textAlign: "center",
              }}
            >
              <Header
                style={{ margin: "10px 0 0 !important", padding: "1rem" }}
                size="large"
              >
                <Header.Content>${company?.debtPaid}</Header.Content>
                <Header.Subheader>Total Debt Paid</Header.Subheader>
              </Header>
            </Card>
          </div>
          <div style={{ overflowX: "scroll" }}>
            <Table
              className="usd-striped usd-bordered usd-table-all usd-blue-background"
              id="clients"
            >
              <Table.Header className="usd-blue-background">
                <Table.HeaderCell style={{ backgroundColor: "#0b6c8c" }}>
                  <h4 className="usd-text-white">PAID</h4>
                </Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {paidClientList?.map((client) => {
                  return (
                    <Table.Row>
                      <Table.Cell>{client.CustomerName}</Table.Cell>
                    </Table.Row>
                  );
                })}
                <Table.Row>
                  <Table.Cell
                    className="usd-blue-background usd-text-white"
                    id="total"
                  >
                    <h3>Total Paid</h3>
                    <Input
                      value={
                        company?.debtPaid > 0
                          ? `$${company?.debtPaid?.toFixed(2)}`
                          : "$0"
                      }
                      type="text"
                      disabled
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.Cell>
                    <Button
                      onClick={() => navigate(routes.authenticated.viewAllPaid)}
                      style={{ background: "#0b6c8c" }}
                      className="usd-button usd-blue-background usd-padding-large usd-text-white"
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ paddingRight: 5 }}
                      />
                      View All
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </div>
          <Form id="customers" onSubmit={handleSubmit}>
            <div style={{ overflowX: "scroll" }}>
              <Table
                className="usd-striped usd-bordered usd-table-all"
                id="search"
              >
                <Table.Header>
                  <Table.HeaderCell style={{ backgroundColor: "#719229" }}>
                    <h4 className="usd-text-white">Search</h4>
                  </Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ display: "block" }}>
                      <Form.Input
                        type="text"
                        label="Name"
                        placeholder="First & Last Name"
                        onChange={(event, value) => {
                          setState({
                            ...state,
                            name: value?.value.toUpperCase(),
                          });
                        }}
                        required
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Form.Input
                        type="text"
                        label="Billing Address"
                        onChange={(event, value) => {
                          setState({
                            ...state,
                            billingAddress: value?.value.toUpperCase(),
                          });
                        }}
                        placeholder="Enter Address associated with the bill"
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Form.Input
                        type="text"
                        label="Service Address"
                        onChange={(event, value) => {
                          setState({
                            ...state,
                            serviceAddress: value?.value.toUpperCase(),
                          });
                        }}
                        placeholder="Enter Address associated with the service location"
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Form.Input
                        onChange={(event, value) => {
                          setState({ ...state, phoneNumber: value?.value });
                        }}
                        type="text"
                        placeholder="Enter the Phone number associated with the bill"
                        label="Phone Number"
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <Form.Input
                        onChange={(event, value) => {
                          setState({
                            ...state,
                            license: value?.value.toUpperCase(),
                          });
                        }}
                        type="text"
                        placeholder="Enter Drivers License Number"
                        label="Driver's License Number"
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
                <Table.Footer>
                  <Table.Cell>
                    <Button
                      type="submit"
                      id="submit"
                      style={{
                        backgroundColor: "#0b6c8c",
                        color: "white",
                        borderRadius: 12,
                      }}
                      className="usd-centered usd-button usd-green-background usd-padding-large"
                    >
                      Search
                    </Button>
                  </Table.Cell>
                </Table.Footer>
              </Table>
            </div>
          </Form>
          <div style={{ overflowX: "scroll" }}>
            <Table className="usd-striped usd-bordered usd-table-all">
              <Table.Header>
                <Table.HeaderCell className="usd-green-background">
                  <h4 className="usd-text-white">Upload File Here</h4>
                </Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                <Table.Row id="file" className="usd-green-background">
                  <Table.Cell>
                    <Form id="upload-file">
                      <span style={{ color: "white" }}>Use </span>
                      <CSVLink data={csvData} style={{ color: "#0b6c8c" }}>
                        this template
                      </CSVLink>
                      <span style={{ color: "white" }}>
                        to upload customers to the database.
                      </span>
                      {fileError && (
                        <div style={{ color: "red" }}>{fileError}</div>
                      )}
                      {fileSuccess && (
                        <div style={{ color: "green" }}>{fileSuccess}</div>
                      )}
                      <span {...getRootProps()}>
                        <>
                          <input {...getInputProps()} />
                          <Button type="button" basic fluid>
                            <FontAwesomeIcon
                              icon={faUpload}
                              style={{ color: "white !important" }}
                            />
                            Upload Files
                          </Button>
                        </>
                      </span>
                    </Form>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </GridColumn>
      </Grid>
      <section
        className="usd-container usd-light-grey"
        style={{ padding: "100px 16px", paddingBottom: "10%" }}
        id="contact"
      >
        <ContactForm />
      </section>
    </>
  );
};
