import React, { useEffect, useState, useRef } from "react";
import { db, functions } from "../auth/firebase";
import { Button, Card, Form, Header, Loader, Modal } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { collection, doc, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import ReCAPTCHA from "react-google-recaptcha";
import { Parishes } from "../assets/parishes";
import moment from "moment";
import { DocumentData } from "firebase/firestore";

const options = [
  { text: "Residential", value: "residential" },
  { text: "Commercial", value: "commercial" },
  { text: "Rental", value: "rental" },
];

interface ParishOptions {
  text: string;
  value: string;
}

export const ClientNewServices = () => {
  const parishOptions: ParishOptions[] = Object.entries(Parishes).map(
    ([key, value]) => ({ text: value, value: key })
  );

  const navigate = useNavigate();
  const [companies, setCompanies] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [company, setCompany] = useState<DocumentData | undefined>();
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [step, setStep] = useState(1);

  const [state, setState] = useState({
    companyId: "",
    CustomerName: "",
    phoneNumber: "",
    email: "",
    date: "",
    serviceAddress: "",
    serviceCity: "",
    serviceState: "",
    serviceZipCode: "",
    billingAddress: "",
    billingCity: "",
    billingState: "",
    billingZipCode: "",
    DL: "",
    DLstate: "",
    propertyType: "",
    electronicSig: "",
    dob: "",
    ssn: "",
    renter: false,
    termsAndConditions: termsAndConditions,
    newMeterInstall: false,
  });

  const currentDate = new Date();

  interface AddClientResponse {
    message: string;
    shouldRedirectToPayment: boolean;
    debtAmount?: number;
  }

  interface AddClientInput {
    companyId: string;
    customerName: string;
    phoneNumber: string;
    email: string;
    date: Date;
    serviceAddress: string;
    billingAddress: string;
    license: string;
    propertyType: string;
    termsAndConditions: boolean;
    electronicSig: string;
    dob: string;
    ssn: string;
    newMeterInstall: boolean;
    recaptchaToken: string;
  }

  const handleSubmit = async () => {
    setLoading(true);

    if (!recaptchaToken) {
      alert("Please complete the reCAPTCHA");
      setLoading(false);
      return;
    }

    try {
      const sessionData: AddClientInput = {
        companyId: state.companyId,
        customerName: state.CustomerName,
        phoneNumber: state.phoneNumber,
        email: state.email,
        date: new Date(),
        serviceAddress: `${state.serviceAddress} ${state.serviceCity}, ${state.serviceState} ${state.serviceZipCode}`,
        billingAddress: `${state.billingAddress} ${state.billingCity}, ${state.billingState} ${state.billingZipCode}`,
        license: `${state.DL} ${state.DLstate}`,
        propertyType: state.propertyType,
        termsAndConditions: state.termsAndConditions,
        electronicSig: state.electronicSig,
        dob: state.dob,
        ssn: state.ssn,
        newMeterInstall: state.newMeterInstall,
        recaptchaToken: recaptchaToken,
      };

      const addClientCallable = httpsCallable<AddClientInput, AddClientResponse>(functions, "addClient");
      const response = await addClientCallable(sessionData);

      const responseData = response.data;

      if (responseData.shouldRedirectToPayment) {
        navigate('/paymentForm', {
          state: { amount: responseData.debtAmount },
        });
      } else if (responseData.message.includes("Client already exists")) {
        alert(responseData.message);
      } else if (responseData.message === "Client added successfully") {
        alert(responseData.message);
        navigate("/about"); // Redirect after successful submission
      }
    } catch (error) {
      console.error("Error creating client:", error);
      alert("An error occurred while creating the account.");
    } finally {
      setLoading(false);
      if (recaptchaRef.current) recaptchaRef.current.reset();
      setRecaptchaToken(null);
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      setLoading(true);
      try {
        let options: any[] = [];
        const companyIds: any[] = [];

        const querySnapshot = await getDocs(
          query(collection(db, "users"), orderBy("companyN"))
        );

        for (const snapshot of querySnapshot.docs) {
          const companyId = snapshot.data().companyN;
          const plan = snapshot.data()?.plan;  // Get the plan object
          const planText = plan?.text;  //for se sfely access the text property

          // Skip if companyId, plan, or planText is empt
          if (!companyId || !planText) {
            console.warn("Skipping due to missing companyId or planText:", { companyId, plan });
            continue;
          }

          // Only include companies with 'Premium' or 'Platinum' plans
          if (!companyIds.includes(companyId) &&
              (planText.includes("Premium") || planText.includes("Platinum"))) {

            const companySnapshot = await getDoc(doc(db, "companies", companyId));
            const companyName = companySnapshot.data()?.Name;

            if (companyName) {
              companyIds.push(companyId);
              options.push({ text: companyName, value: companyId });
            }
          }
        }

        options.sort((a, b) => a.text.localeCompare(b.text));
        setCompanies(options);
      } catch (error) {
        console.error("Error fetching companies:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      if (state.companyId) {
        try {
          const result = await getDoc(doc(db, "companies", state.companyId));
          setCompany(result.data());
        } catch (error) {
          console.error("Error fetching company details:", error);
        }
      }
    };

    fetchCompanyDetails();
  }, [state.companyId]);

  if (loading) {
    return <Loader active />;
  }

  const renderStep1 = () => (
    <div>
      <Form.Input
        label="Name: "
        name="CustomerName"
        value={state.CustomerName}
        placeholder=" "
        onChange={(event, value) =>
          setState({ ...state, CustomerName: value.value })
        }
        required
      />
      <Form.Input
        label="Email Address: "
        name="email"
        value={state.email}
        placeholder=" "
        onChange={(event, value) =>
          setState({ ...state, email: value.value })
        }
        required
      />
      <Button
        onClick={() => setStep(2)}
        disabled={!state.CustomerName || !state.email}
        style={{ backgroundColor: "#719229", color: "white" }}
      >
        Continue
      </Button>
    </div>
  );

  const renderStep2 = () => (
    <div>
      <Form.Dropdown
        search
        selection
        options={companies}
        onChange={(event, value) => {
          if (value.value) {
            setState({ ...state, companyId: value.value?.toString() });
          }
        }}
        placeholder="Select a Company"
        defaultValue=""
        required
      />

      <Form.Input
        label="Date of Birth"
        required
        name="dob"
        value={state.dob}
        placeholder="mm/dd/yyyy"
        onChange={(event, value) =>
          setState({ ...state, dob: value.value })
        }
      />

      <Form.Input
        label="Cell Phone:  "
        name="phoneNumber"
        value={state.phoneNumber}
        placeholder=" "
        onChange={(event, value) =>
          setState({ ...state, phoneNumber: value.value })
        }
        required
      />
      <Form.Input
        label=" Last 4 of SSN "
        name="ssn"
        value={state.ssn}
        placeholder="xxx-xx-xxxx"
        onChange={(event, value) =>
          setState({ ...state, ssn: value.value })
        }
        required
      />
      <Button
        onClick={() => setStep(1)}
        style={{ marginRight: "10px" }}
      >
        Back
      </Button>
      <Button
        onClick={() => setStep(3)}
        disabled={!state.companyId || !state.dob || !state.phoneNumber || !state.ssn}
        style={{ backgroundColor: "#719229", color: "white" }}
      >
        Continue
      </Button>
    </div>
  );

  const renderStep3 = () => (
    <div>
      <Form.Input
        label="Street Address"
        name="serviceAddress"
        value={state.serviceAddress}
        placeholder=" "
        onChange={(event, value) =>
          setState({ ...state, serviceAddress: value.value })
        }
        required
      />
      <Form.Group widths="equal">
        <Form.Input
          label="City"
          name="serviceCity"
          value={state.serviceCity}
          placeholder=" "
          onChange={(event, value) =>
            setState({ ...state, serviceCity: value.value })
          }
          required
        />
        <Form.Input
          label="State"
          name="serviceState"
          value={state.serviceState}
          placeholder=" "
          onChange={(event, value) =>
            setState({ ...state, serviceState: value.value })
          }
          required
        />
        <Form.Input
          label="Zip Code"
          name="serviceZipCode"
          value={state.serviceZipCode}
          placeholder=" "
          onChange={(event, value) =>
            setState({ ...state, serviceZipCode: value.value })
          }
          required
        />
      </Form.Group>

      <Form.Input
        label="Billing Address"
        name="billingAddress"
        value={state.billingAddress}
        placeholder=" "
        onChange={(event, value) =>
          setState({ ...state, billingAddress: value.value })
        }
        required
      />
      <Form.Group widths="equal">
        <Form.Input
          label="City"
          name="billingCity"
          value={state.billingCity}
          placeholder=" "
          onChange={(event, value) =>
            setState({ ...state, billingCity: value.value })
          }
          required
        />
        <Form.Input
          label="State"
          name="billingState"
          value={state.billingState}
          placeholder=" "
          onChange={(event, value) =>
            setState({ ...state, billingState: value.value })
          }
          required
        />
        <Form.Input
          label="Zip Code"
          name="billingZipCode"
          value={state.billingZipCode}
          placeholder=" "
          onChange={(event, value) =>
            setState({ ...state, billingZipCode: value.value })
          }
          required
        />
      </Form.Group>

      <Form.Checkbox
        toggle
        inline
        name="newMeterInstall"
        label="New Meter Needed?"
        onChange={() => {
          setState({ ...state, newMeterInstall: !state.newMeterInstall });
        }}
      />

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        trigger={
          <Form.Checkbox
            label="User Agreement"
            required
            name="userAgreement"
            checked={termsAndConditions}
            onChange={(e, { checked }) => {
              setOpen(true);
            }}
          />
        }
      >
        <Modal.Header
          style={{ textAlign: "center" }}
          className="usd-green-background usd-text-white"
        >
          User Agreement
          <br />
        </Modal.Header>
        <div
          className="usd-blue-background usd-text-white usd-padding-16 usd-medium"
          style={{ paddingLeft: 10 }}
        >
          FOR THE SERVICE OF: <b> {company?.Name} </b>
          <br />
          <br />
          ADDRESS: {company?.address} <br />
        </div>
        <Modal.Content scrolling>
          <Form>
            I am applying for membership at{" "}
            <b className="usd-text-red"> {company?.Name} </b>, domiciled
            in <b className="usd-text-red">{company?.Location}</b>{" "}
            Parish, Louisiana.
            <br />
            I agree to pay the deposit(s) amount and connection fee(s)
            as listed on the rate sheet and all other sums required as
            they come due and payable. I agree to abide by the Articles
            of Incorporation, By-Laws, Rules and Regulations, and
            Resolutions of the Council (or) Board of Directors of the
            Utility Services. Deposit(s) Agreement:
            <br />
            This agreement is between the{" "}
            <b className="usd-text-red">{company?.Name} </b>
            hereinafter called SUPPLIER, and you, a tenant utility user
            hereinafter called TENANT.
            <br />
            Now, therefore, it is hereby understood and agreed: The
            Supplier shall furnish, subject to limitations set out in
            the Supplier’s Rules and Regulations and By-Laws, such
            quantity of service as the Tenant may desire in connection
            with his occupancy. The Tenant agrees to pay for services at
            such rates, time and place as shall be determined and
            designated by the Supplier and agrees to the penalties for
            non-payment as set out in the current Rules and Regulations.
            The Tenant agrees to pay a service deposit at the current
            rate in force for the above-named utility service. The
            deposit shall be held and applied by the Supplier to the
            payment of the account of Tenant should service to the
            Tenant be terminated whether voluntarily by Tenant or
            involuntarily by Supplier. Supplier may, in its discretion,
            apply the deposit to amounts owed Supplier by Tenant and if
            any monies of the deposit are leftover Supplier will refund
            said amount, if any, to Tenant within thirty (30) days after
            final termination of service. Any balance owed after
            applying the deposit the Tenant agrees to pay the unpaid
            balance within thirty (30) days of final presentation of
            said final bill. If Tenant fails to pay the final bill after
            said thirty (30) days, then Supplier has the right to
            proceed with collection efforts with all costs of collection
            to be paid by Tenant. The member agrees that deposits or
            connection fees for other services such as sewer, trash
            pickup, or any other service provided by this supplier is
            covered by this contract. All monies collected by this
            service will be paid to the supplier as one account. The
            Failure of a customer to pay charges duly imposed shall
            result in the automatic imposition of the following
            penalties:
            <br />
            1. Nonpayment within 10 days from the due date will be
            subject to a penalty
            <b className="usd-text-red">
              {" "}
              {company?.lateCharge}{" "}
            </b>
            percent of the delinquent account.
            <br />
            2. Nonpayment within 30 days from the due date will result
            in the service being shut off from the customer’s property.
            <br />
            3. In the Event, it becomes necessary for the Utility
            Service to shut off the service(s) from Member’s property, a
            <b className="usd-text-red">
              {" "}
              ${company?.disconnectFee}{" "}
            </b>
            fee set by the corporation in its rate schedule will be
            charged for reconnection of the service.
            <br />
            4. A{" "}
            <b className="usd-text-red">
              {" "}
              ${company?.returnedCheckFee}{" "}
            </b>
            return check fee for all return check, utility may be
            subject to disconnect for non-payment.
            <br />
            Tampering with a Meter(s) or stealing services from a
            utility service is unlawful and such utilities are protected
            by municipal ordinance (section1-8, 98-104) and State law
            (R.S. 14;67.6). Said violation is subject to a fine of up to
            $500.00 or imprisonment of not more than 60 days, or both.
            Additionally, should tampering include any destruction of
            supplier’s property, additional criminal charges may be
            filed. In the event service to the member is terminated,
            either voluntarily by the member, or by the Association for
            any cause, any membership fee and the setup fee shall be
            held by the Association. The Deposit will be refunded after
            the account is a balance of $0.00. The Member shall commence
            to use their service from the supplier on the date the
            service is made available to the member by the Supplier.
            Charges to the Member shall commence on the date service is
            made available, regardless of whether the member connects to
            the system.
            <br />
            Signed this <br />
            <div style={{ display: "flex" }}>
              Date: <br />
              <b> {currentDate.toDateString()}</b>
              by, <br />
              <Form.Input
                placeholder="Electronic Signature"
                value={state.electronicSig}
                name="electronicSig"
                width={2}
                onChange={(event, value) =>
                  setState({ ...state, electronicSig: value.value })
                }
                required
              />
            </div>
            I UNDERSTAND BY COMPLETING AND SUBMITTING THIS APPLICATION
            THAT ANY AND ALL INFORMATION OBTAINED WITHIN THIS
            APPLICATION WILL BE USED TO SEARCH THE UTILITY SERVICE
            DATABASE FOR ANY OUTSTANDING DEBT WITH OTHER UTILITY
            SUPPLIERS.
            <br /> ANY OUTSTANDING DEBT WITH OTHER UTILITY SERVICES WILL
            BE ADDED TO THE INVOICE FOR SERVICE, AND MUST BE PAID IN
            FULL ALONG WITH ALL DEPOSITS, CONNECTION FEES, MEMBERSHIP
            FEES, OR DATABASE FEES FOR{" "}
            <b className="usd-text-red">{company?.Name} </b> BEFORE NEW
            SERVICE CAN BE ACTIVATED.
            <Form.Button
              width={2}
              size="mini"
              style={{
                fontWeight: "bold",
                paddingTop: "10%",
                backgroundColor: "#719229",
                color: "white",
              }}
              onClick={() => {
                if (state.electronicSig !== "") {
                  setOpen(false);
                  setTermsAndConditions(true);
                }
              }}
              required
            >
              Agree
            </Form.Button>
          </Form>
        </Modal.Content>
      </Modal>

      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LfPBTYqAAAAAMBbLIqMFZzTMtKbade5JMPtHBau" 
        onChange={(token) => setRecaptchaToken(token)}
      />

      <Button
        onClick={handleSubmit}
        disabled={!termsAndConditions || !recaptchaToken || !state.serviceAddress}
        style={{ backgroundColor: "#719229", color: "white", marginTop: "10px" }}
      >
        Submit
      </Button>
      <Button
        onClick={() => setStep(2)}
        style={{ marginRight: "10px", marginTop: "10px" }}
      >
        Back
      </Button>
    </div>
  );

  return (
    <div
      className="usd-container usd-light-grey"
      style={{ padding: "128px 16px" }}
      id="newClient"
    >
      <Card style={{ width: 700, padding: 25, margin: "auto" }}>
        <Card.Header style={{ textAlign: "center" }}>
          <Header as="h1">UTILITY SERVICE DATABASE LLC.</Header>
          <Header as="h6" style={{ marginTop: 0, marginBottom: 20 }}>
            632 Longino Road, Coushatta LA 71019 <br />
            Call center: (318)702-7071 <br /> Email: utilitydatabase23@gmail.com
          </Header>
        </Card.Header>

        <Card.Content>
          <Form>
            {step === 1 && renderStep1()}
            {step === 2 && renderStep2()}
            {step === 3 && renderStep3()}
          </Form>
        </Card.Content>
      </Card>
    </div>
  );
};
