import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Form, Loader, Table } from "semantic-ui-react";
import { db, functions } from "../auth/firebase";
import { httpsCallable } from "firebase/functions";
import { Statuses } from "../enums";

export const ClientSearchResults = () => {
  const location = useLocation();
  const searchParameters = location.state.data;
  const [customers, setCustomers] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchClients = async () => {
      let tempCustomers: any[] = [];
      const checkName = await getDocs(
        query(
          collection(db, "clients"),
          where("CustomerName", "==", searchParameters.name)
        )
      );
      checkName.forEach((doc) => {
        tempCustomers.push({ id: doc.id, ...doc.data() });
      });
      if (searchParameters.billingAddress !== "") {
        const checkBillingAddress = await getDocs(
          query(
            collection(db, "clients"),
            where("billingAddress", "==", searchParameters.billingAddress)
          )
        );
        checkBillingAddress.forEach((doc) => {
          const value = doc.data();
          if (!tempCustomers.find((x) => x.CustomerName === value.CustomerName))
            tempCustomers.push({ id: doc.id, ...doc.data() });
        });
      }
      if (searchParameters.serviceAddress !== "") {
        const checkServiceAddress = await getDocs(
          query(
            collection(db, "clients"),
            where("serviceAddress", "==", searchParameters.serviceAddress)
          )
        );
        checkServiceAddress.forEach((doc) => {
          const value = doc.data();
          if (
            !tempCustomers.find(
              (x) =>
                x.CustomerName === value.CustomerName &&
                x.billingAddress === value.billingAddress
            )
          )
            tempCustomers.push({ id: doc.id, ...doc.data() });
        });
      }
      if (searchParameters.license !== "") {
        const checkLicense = await getDocs(
          query(
            collection(db, "clients"),
            where("DriversLicense", "==", searchParameters.license)
          )
        );
        checkLicense.forEach((doc) => {
          const value = doc.data();
          if (
            !tempCustomers.find(
              (x) =>
                x.CustomerName === value.CustomerName &&
                x.billingAddress === value.billingAddress &&
                x.serviceAddress === value.serviceAddress
            )
          )
            tempCustomers.push({ id: doc.id, ...doc.data() });
        });
      }
      if (searchParameters.phoneNumber !== "") {
        const checkPhoneNumber = await getDocs(
          query(
            collection(db, "clients"),
            where("phoneNumber", "==", searchParameters.phoneNumber)
          )
        );
        checkPhoneNumber.forEach((doc) => {
          const value = doc.data();
          if (
            !tempCustomers.find(
              (x) =>
                x.CustomerName === value.CustomerName &&
                x.billingAddress === value.billingAddress &&
                x.serviceAddress === value.serviceAddress &&
                x.DriversLicense === value.DriversLicense
            )
          )
            tempCustomers.push({ id: doc.id, ...doc.data() });
        });
      }
      const customersWithCompanyName = await Promise.all(
        tempCustomers.map(async (customer) => {
          const companySnapshot = await getDoc(
            doc(db, "companies", customer.CompanyId)
          );
          const companyData = companySnapshot.data();
          return {
            ...customer,
            companyName: companyData?.Name,
            disconnectFee:
              customer.Status === Statuses.Disconnect
                ? companyData?.disconnectFee
                : 0,
          };
        })
      );

      setCustomers(
        customersWithCompanyName.sort((a, b) =>
          a.CustomerName > b.CustomerName ? 1 : -1
        )
      );
    };
    fetchClients();
    setLoading(false);
  }, [searchParameters]);

  const getInterest = (amount: any) => {
    if (amount > 0) {
      if (amount < 100) {
        amount = 25;
      } else {
        amount = (Number(amount) * 0.25 + 25).toFixed();
      }
    }
    return amount;
  };

  const handleSubmit = async (customer: any) => {
    setLoading(true);
    const id = customer.id;
    const companyId = customer.CompanyId;
    const type = "Debt";
    var lineItems = [
      {
        quantity: 1,
        price_data: {
          currency: "usd",
          unit_amount_decimal: (Number(customer.debt) * 100).toFixed(0),
          product_data: {
            name: `Debt`,
          },
        },
      },
      {
        quantity: 1,
        price_data: {
          currency: "usd",
          unit_amount_decimal: (
            Number(getInterest(customer.debt)) * 100
          ).toFixed(0),
          product_data: {
            name: "USD Fee",
          },
        },
      },
    ];
    if (customer.Status === Statuses.Disconnect) {
      lineItems.push({
        quantity: 1,
        price_data: {
          currency: "usd",
          unit_amount_decimal: (Number(customer.disconnectFee) * 100).toFixed(
            0
          ),
          product_data: {
            name: "Disconnect Fee",
          },
        },
      });
    }
    try {
      const createCheckoutSessionCallable = httpsCallable(
        functions,
        "createCheckoutSession"
      );
      createCheckoutSessionCallable({
        lineItems,
        id,
        type,
        companyId,
      }).then((response) => {
        const { redirectUrl } = response.data as { redirectUrl: string };
        window.location.href = redirectUrl;
      });
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };
  if (loading) {
    return <Loader active />;
  }
  return (
    <>
      {loading && <Loader active />}
      <Form
        className="usd-container usd-light-grey"
        style={{ padding: "128px 16px" }}
        id="clientSearch"
      >
        <ul className="usd-ul usd-grey usd-hover-shadow">
          <li
            className="usd-green-background usd-center usd-xlarge usd-padding-16"
            style={{ color: "white" }}
          >
            Select Your Account
          </li>
        </ul>

        <div style={{ overflowX: "scroll" }}>
          <Table className="usd-striped usd-bordered usd-table-all">
            <Table.Header celled padded selectable columns={5}>
              <Table.HeaderCell style={{ position: "sticky" }}>
                NAME
              </Table.HeaderCell>
              <Table.HeaderCell style={{ position: "sticky" }}>
                SERVICE ADDRESS
              </Table.HeaderCell>
              <Table.HeaderCell style={{ position: "sticky" }}>
                COMPANY
              </Table.HeaderCell>
              <Table.HeaderCell style={{ position: "sticky" }}>
                DEBT
              </Table.HeaderCell>
              <Table.HeaderCell style={{ position: "sticky" }}>
                TOTAL WITH USD FEES
              </Table.HeaderCell>
              <Table.HeaderCell style={{ position: "sticky" }}>
                {" "}
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {customers?.map((customer) => (
                <Table.Row>
                  <Table.Cell>{customer.CustomerName}</Table.Cell>
                  <Table.Cell>{customer.serviceAddress}</Table.Cell>
                  <Table.Cell>{customer.companyName}</Table.Cell>
                  <Table.Cell>{customer.debt}</Table.Cell>
                  <Table.Cell>
                    {(
                      Number(getInterest(customer.debt)) +
                      Number(customer.debt) +
                      Number(customer.disconnectFee)
                    ).toFixed(2)}
                  </Table.Cell>
                  <Table.Cell>
                    <Form.Button
                      type="submit"
                      id="submit"
                      onClick={() => handleSubmit(customer)}
                      primary
                    >
                      Select
                    </Form.Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Form>
    </>
  );
};
