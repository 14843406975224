export const Parishes = {
  Acadia: "Acadia",
  Allen: "Allen ",
  Ascension: "Ascension",
  Assumption: "Assumption",
  Avoyelles: "Avoyelles",
  Beauregard: " Beauregard",
  Bienville: "Bienville",
  Bossier: "Bossier",
  Caddo: "Caddo",
  Calcasieu: "Calcasieu ",
  Caldwell: " Caldwell",
  Cameron: "Cameron",
  Catahoula: "Catahoula",
  Claiborne: "Claiborne",
  Concordia: "Concordia",
  DeSoto: "DeSoto",
  EastBatonRouge: "East Baton Rouge",
  EastCarroll: "East Carroll",
  EastFeliciana: "East Feliciana",
  Evangeline: "Evangeline",
  Franklin: "Franklin",
  Grant: "Grant",
  Iberia: "Iberia",
  Iberville: "Iberville",
  Jackson: "Jackson",
  Jefferson: "Jefferson",
  JeffersonDavis: "Jefferson Davis",
  LaSalle: "LaSalle",
  Lafayette: "Lafayette",
  Lafourche: "Lafourche",
  Lincoln: "Lincoln",
  Livingston: "Livingston",
  Madison: "Madison",
  Morehouse: "Morehouse",
  Natchitoches: "Natchitoches",
  Orleans: "Orleans",
  Ouachita: "Ouachita",
  Plaquemines: "Plaquemines",
  PointeCoupee: "Pointe Coupee",
  Rapides: "Rapides",
  RedRiver: "Red River",
  Richland: "Richland",
  Sabine: "Sabine",
  StBernard: "St. Bernard",
  StCharles: "St. Charles",
  StHelena: "St. Helena",
  StJames: "St. James",
  StJohnTheBaptist: "St. John The Baptist",
  StLandry: "St. Landry",
  StMartin: "St. Martin",
  StMary: "St. Mary",
  StTammany: "St. Tammany",
  Tangipahoa: "Tangipahoa",
  Tensas: "Tensas",
  Terrebonne: "Terrebonne",
  Union: "Union",
  Vermilion: "Vermilion",
  Vernon: "Vernon",
  Washington: "Washington",
  Webster: "Webster",
  WestBatonRouge: "West Baton Rouge",
  WestCarroll: "West Carroll",
  WestFeliciana: "West Feliciana",
  Winn: "Winn",
};
